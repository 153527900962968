<div class="drawer-header header-drawer-custom">
  <div class="header-container">
    <div class="back-to">
      <a (click)="cancel()"><ion-icon name="chevron-back-outline"></ion-icon>Back to profile </a>
      <div class="close-block" (click)="cancel()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </div>
    </div>
  </div>
</div>

<ion-header class="ion-no-border header-top-custom">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-title class="title-size" position="floating">{{ formTitle }}</ion-title>
    </ion-buttons>
    <ion-title>{{ mode === 'edit' ? 'Update' : 'Add' }} Leg</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-horizontal content-right-block">
  <div class="form-container" [formGroup]="addOption">
    <!-- <div class="input-block-custom custom-opacity"> -->
    <!-- <ion-label *ngIf="formType === 'long'" position="floating">Purchase Date</ion-label> -->
    <!-- <ion-label *ngIf="formType === 'weekly'" position="floating">Date Sold</ion-label> -->
    <!-- <ion-input -->
    <!-- formControlName="purchasedDate" -->
    <!-- readonly="true" -->
    <!-- (click)="openDatePicker()" -->
    <!-- (focus)="openDatePicker()" -->
    <!-- placeholder="MM/DD/YYYY" -->
    <!-- ></ion-input> -->
    <!-- <ion-datetime -->
    <!-- presentation="date" -->
    <!-- (ionChange)="closeDatePicker($event)" -->
    <!-- [hidden]="!showDatePicker" -->
    <!-- displayFormat="MM/DD/YYYY" -->
    <!-- pickerFormat="MM DD YYYY" -->
    <!-- ></ion-datetime> -->
    <!-- </div> -->

    <div class="input-block-custom custom-opacity">
      <!-- Display the appropriate label based on formType -->
      <ion-label *ngIf="formType === 'long'" position="floating">Purchase Date</ion-label>
      <ion-label *ngIf="formType === 'weekly'" position="floating">Date Sold</ion-label>

      <!-- Make the input field editable by removing readonly -->
      <ion-input
        formControlName="purchasedDate"
        (click)="openDatePicker()"
        (focus)="openDatePicker()"
        placeholder="MM/DD/YYYY"
      ></ion-input>

      <!-- Date picker which will be triggered by user action -->
      <ion-datetime
        presentation="date"
        (ionChange)="closeDatePicker($event)"
        [hidden]="!showDatePicker"
        displayFormat="MM/DD/YYYY"
        pickerFormat="MM DD YYYY"
      ></ion-datetime>
    </div>
    <div
      class="input-block-custom"
      *ngIf="componentData && componentData?.isPositionExpired === false"
    >
      <ion-label>Expiration Date</ion-label>
      <ion-select
        line="none"
        formControlName="expirationDate"
        okText="Okay"
        (ionChange)="getListStrike(symbol, $event, 'fromExpirationDate')"
        cancelText="Dismiss"
        interface="popover"
      >
        <ion-select-option *ngFor="let expirationDate of listOfExpiration" [value]="expirationDate">
          {{ expirationDate }}
        </ion-select-option>
      </ion-select>
    </div>
    <div class="input-block-custom custom-opacity" *ngIf="componentData.isPositionExpired === true">
      <ion-label position="floating">Expiration Date</ion-label>
      <ion-input formControlName="expirationDate" type="text"></ion-input>
    </div>
    <ion-row class="content-row underlyingprice-block">
      <ion-col size-xl="6" size-lg="6" class="pl-0">
        <div
          class="input-block-custom custom-opacity"
          *ngIf="componentData && componentData?.isPositionExpired === false"
        >
          <ion-label *ngIf="formType === 'long'">Strike Bought</ion-label>
          <ion-label *ngIf="formType === 'weekly'">Strike Sold</ion-label>
          <div class="space-left-block custom-opacity">
            <ion-select
              line="none"
              formControlName="strikeBought"
              okText="Okay"
              cancelText="Dismiss"
              [disabled]="!addOption.get('expirationDate').value"
              (ionChange)="getPremiumPaid($event)"
              interface="popover"
            >
              <ion-select-option
                *ngFor="let position of positionDetail"
                value="{{ position.strike?.raw }}"
              >
                $ {{ position.strike?.raw }}
              </ion-select-option>
            </ion-select>
          </div>
        </div>
        <div
          class="input-block-custom custom-opacity"
          *ngIf="componentData.isPositionExpired === true"
        >
          <ion-label *ngIf="formType === 'long'">Strike Bought</ion-label>
          <ion-label *ngIf="formType === 'weekly'">Strike Sold</ion-label>
          <ion-input formControlName="strikeBought" type="text"></ion-input>
        </div>
      </ion-col>
      <ion-col size-xl="6" size-lg="6" class="pr-0">
        <div class="input-block-custom custom-opacity">
          <ion-label>Underlying Price</ion-label>
          <div class="underlyingprice-left">
            <span class="dor">$</span>
            <ion-input formControlName="underlyingPrice" type="number" min="0"></ion-input>
          </div>
        </div>
      </ion-col>
    </ion-row>
    <ion-col size-xl="12" size-lg="12" class="p-0">
      <div class="input-block-custom custom-opacity">
        <ion-label position="floating"># Of Contracts</ion-label>
        <ion-input
          formControlName="contracts"
          type="number"
          inputmode="decimal"
          min="0"
        ></ion-input>
      </div>
    </ion-col>
    <ion-col size-xl="12" size-lg="12" *ngIf="formType === 'long'" class="p-0">
      <div class="input-block-custom custom-opacity">
        <ion-label>Premium Paid</ion-label>
        <div class="underlyingprice-left">
          <span class="dor">$</span>
          <ion-input formControlName="premiumPaid" type="number" min="0"></ion-input>
        </div>
      </div>
    </ion-col>
    <ion-col size-xl="12" size-lg="12" *ngIf="formType === 'weekly'" class="p-0">
      <div class="input-block-custom custom-opacity">
        <ion-label>Credit</ion-label>
        <div class="underlyingprice-left">
          <span class="dor">$</span>
          <ion-input formControlName="credit" type="number" min="0"></ion-input>
        </div>
      </div>
    </ion-col>
    <ion-col
      size-xl="12"
      size-lg="12"
      *ngIf="mode === 'edit' && statusChange === 'closed'"
      class="p-0"
    >
      <div class="input-block-custom">
        <ion-label>Exit Premium</ion-label>
        <div class="underlyingprice-left">
          <span class="dor">$</span>
          <ion-input formControlName="exitPremium" type="number" min="0"></ion-input>
        </div>
      </div>
    </ion-col>
    <ion-item class="ion-item-no-inner-padding" *ngIf="mode === 'edit'">
      <ion-label>Status</ion-label>
      <div class="toggle-block">
        <div class="toggle-button-cover">
          <div class="button-cover">
            <div class="button" id="button-1">
              <input
                type="checkbox"
                class="checkbox"
                [checked]="isStatusChecked"
                (change)="toggleStatus($event)"
              />
              <div class="knobs"></div>
              <div class="layer"></div>
            </div>
          </div>
        </div>
      </div>
    </ion-item>
    <ion-footer class="ion-no-border">
      <ion-toolbar>
        <ion-button
          type="submit"
          color="primary"
          fill="solid"
          (click)="createLegs()"
          [disabled]="addOption.invalid"
          style="width: 100%"
        >
          {{ mode === 'edit' ? 'Update' : 'Create' }} Leg
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </div>
</ion-content>
