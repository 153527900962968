<div class="header-notification notification-page-container" appScrollbar [directInject]="true">
  <div
    class="notification-body"
    [scrollWindow]="false"
    infiniteScroll
    [infiniteScrollDistance]="scrollDistance"
    [infiniteScrollUpDistance]="scrollUpDistance"
    [infiniteScrollThrottle]="throttle"
    (scrolled)="loadNotificationsInfinitely()"
  >
    <div class="notification-empty" *ngIf="notifications.length === 0">No recent notifications</div>
    <div
      class="notification"
      [ngClass]="{ 'seen-notification': notification.isRead }"
      *ngFor="let notification of formatedNotifications; let i = index"
      (click)="notificationRedirect(notification.hrefLink, notification.id, i)"
    >
      <div [ngSwitch]="notification.type" style="width: 100%">
        <div *ngSwitchCase="'ChatComment'" style="width: 100%">
          <div class="left-section">
            <div class="user-profile">
              <img
                ngxGravatar
                [email]="notification.commentAuthor.email"
                [size]="platfrom !== 'web' ? 35 : 45"
                src="{{ notification.commentAuthor.image }}"
              />
            </div>
            <div class="notification-info chat-notification-info">
              <div>
                <div class="message-container" [innerHtml]="notification.message | keepHtml"></div>
                <div
                  class="comment"
                  *ngIf="notification.comment"
                  [innerHtml]="notification.comment.commentText | keepHtml"
                ></div>
                <div class="time">{{ notification.dateTime | moment }}</div>
              </div>
              <ion-buttons class="ion-hide-sm-down">
                <ion-button
                  *ngIf="!notification.isRead"
                  (click)="
                    $event.preventDefault();
                    $event.stopPropagation();
                    markAsRead(notification.id, i)
                  "
                >
                  <ion-icon color="primary" slot="icon-only" name="checkmark-outline"></ion-icon>
                </ion-button>
                <ion-button
                  (click)="
                    $event.preventDefault();
                    $event.stopPropagation();
                    clearNotification(notification, i)
                  "
                >
                  <ion-icon color="danger" slot="icon-only" name="trash-outline"></ion-icon>
                </ion-button>
              </ion-buttons>
              <ion-buttons class="ion-hide-sm-up">
                <ion-button
                  color="primary"
                  fill="outline"
                  *ngIf="!notification.isRead"
                  (click)="
                    $event.preventDefault();
                    $event.stopPropagation();
                    markAsRead(notification.id, i)
                  "
                >
                  <ion-icon slot="icon-only" name="checkmark-outline"></ion-icon>
                </ion-button>
                <ion-button
                  fill="outline"
                  color="danger"
                  (click)="
                    $event.preventDefault();
                    $event.stopPropagation();
                    clearNotification(notification, i)
                  "
                >
                  <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
                </ion-button>
              </ion-buttons>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'Broadcast'" style="width: 100%">
          <div class="left-section">
            <div class="broadcast-logo">
              <div class="image-container">
                <img src="/assets/icon/broadcast-speaker.png" />
              </div>
            </div>
            <div class="notification-info broadcast-notification-info">
              <div>
                <div class="broadcast-title">New Broadcast Message</div>
                <div class="broadcast-subject">{{ notification.subject }}</div>
                <div class="broadcast-message" [innerHtml]="notification.message | keepHtml"></div>
                <div class="time">{{ notification.dateTime | moment }}</div>
              </div>
              <ion-buttons class="ion-hide-sm-down">
                <ion-button
                  *ngIf="!notification.isRead"
                  (click)="
                    $event.preventDefault();
                    $event.stopPropagation();
                    markAsRead(notification.id, i)
                  "
                >
                  <ion-icon color="primary" slot="icon-only" name="checkmark-outline"></ion-icon>
                </ion-button>
                <ion-button
                  (click)="
                    $event.preventDefault();
                    $event.stopPropagation();
                    clearNotification(notification, i)
                  "
                >
                  <ion-icon color="danger" slot="icon-only" name="trash-outline"></ion-icon>
                </ion-button>
              </ion-buttons>
              <ion-buttons class="ion-hide-sm-up">
                <ion-button
                  color="primary"
                  fill="outline"
                  *ngIf="!notification.isRead"
                  (click)="
                    $event.preventDefault();
                    $event.stopPropagation();
                    markAsRead(notification.id, i)
                  "
                >
                  <ion-icon slot="icon-only" name="checkmark-outline"></ion-icon>
                </ion-button>
                <ion-button
                  fill="outline"
                  color="danger"
                  (click)="
                    $event.preventDefault();
                    $event.stopPropagation();
                    clearNotification(notification, i)
                  "
                >
                  <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
                </ion-button>
              </ion-buttons>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'InsiderChat'" style="width: 100%">
          <div class="left-section">
            <div class="user-profile">
              <img
                ngxGravatar
                [email]="notification.threadAuthor.email"
                [size]="platfrom !== 'web' ? 35 : 45"
                src="{{ notification.threadAuthor.image }}"
              />
            </div>
            <div class="notification-info chat-notification-info">
              <div>
                <div class="message-container" [innerHtml]="notification.message | keepHtml"></div>
                <div
                  class="comment"
                  *ngIf="notification.thread"
                  [innerHtml]="notification.thread.threadText | keepHtml"
                ></div>
                <div class="time">{{ notification.dateTime | moment }}</div>
              </div>
              <ion-buttons class="ion-hide-sm-down">
                <ion-button
                  *ngIf="!notification.isRead"
                  (click)="
                    $event.preventDefault();
                    $event.stopPropagation();
                    markAsRead(notification.id, i)
                  "
                >
                  <ion-icon color="primary" slot="icon-only" name="checkmark-outline"></ion-icon>
                </ion-button>
                <ion-button
                  (click)="
                    $event.preventDefault();
                    $event.stopPropagation();
                    clearNotification(notification, i)
                  "
                >
                  <ion-icon color="danger" slot="icon-only" name="trash-outline"></ion-icon>
                </ion-button>
              </ion-buttons>
              <ion-buttons class="ion-hide-sm-up">
                <ion-button
                  color="primary"
                  fill="outline"
                  *ngIf="!notification.isRead"
                  (click)="
                    $event.preventDefault();
                    $event.stopPropagation();
                    markAsRead(notification.id, i)
                  "
                >
                  <ion-icon slot="icon-only" name="checkmark-outline"></ion-icon>
                </ion-button>
                <ion-button
                  fill="outline"
                  color="danger"
                  (click)="
                    $event.preventDefault();
                    $event.stopPropagation();
                    clearNotification(notification, i)
                  "
                >
                  <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
                </ion-button>
              </ion-buttons>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
