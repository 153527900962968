<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Select Trade</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-horizontal">
  <!-- <div class="waring-label">
    <div class="icon">
      <ion-icon name="warning-outline"></ion-icon>
    </div>
    <div class="label">ARE YOU SURE TO CHANGE TICKER?</div>
    <div class="action">
      <ion-button fill="clear">YES</ion-button>
      <ion-button fill="clear" style="border-left: 1px solid #e1b200"><span>NO</span></ion-button>
    </div>addTicker
  </div> -->
  <div class="form-container">
    <form (ngSubmit)="onAddNewTrade()" [formGroup]="addTickerTradeForm">
      <!-- <div class="input-container">
        <ion-label>Ticker</ion-label>
        <ion-item class="ion-item-no-inner-padding">
          <ion-select
            lines="none"
            placeholder="Select The Ticker"
            interface="popover"
            [interfaceOptions]="customPopoverOptions"
            formControlName="ticker"
            (ionScroll)="loadMoreData($event)"
          >
            <ion-select-option *ngFor="let item of visibleTickerList" [value]="item.stock_id">{{
              item.name
            }}</ion-select-option>
            <ion-select-option *ngIf="showLoadMoreOption" value="loadMoreOption">
              Load More
            </ion-select-option>
          </ion-select>
        </ion-item>
        <div
          *ngIf="
            addTickerTradeForm.get('ticker').invalid && addTickerTradeForm.get('ticker').touched
          "
        >
          <div *ngIf="addTickerTradeForm.get('ticker').errors.required">Ticker is required.</div>
        </div>
      </div> -->
      <div class="input-container">
        <!-- <ion-label>Ticker</ion-label> -->
        <ion-searchbar
          search-icon="search-outline"
          debounce="1000"
          id="search"
          class="ion-no-padding"
          placeholder="Type name or ticker symbol"
          (ionChange)="onSearchLookupNew($event)"
          [value]="
            selectedTicker.symbol && selectedTicker.name
              ? selectedTicker.symbol + ' - ' + selectedTicker.name
              : ''
          "
        >
        </ion-searchbar>
        <div
          class="taglist-search"
          *ngIf="tickerListVisibility && searchResult && searchResult.length > 0"
        >
          <span class="close" (click)="hideTickerList()">&#10005;</span>
          <p *ngFor="let item of searchResult" (click)="addTicker(item.symbol, item.name)">
            {{ item.symbol }} - {{ item.name }}
          </p>
        </div>
      </div>
      <ion-button
        [disabled]="addTickerTradeForm.invalid"
        type="submit"
        color="primary"
        fill="solid"
        style="width: 100%"
      >
        Save And Continue
      </ion-button>
    </form>
  </div>
  <!-- <ion-button type="submit" color="primary" fill="solid" style="width: 100%">
    Save And Continue
  </ion-button> -->
</ion-content>
<ion-footer class="ion-no-border"> </ion-footer>
