import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-trade-close-model',
  templateUrl: './trade-close-model.component.html',
  styleUrls: ['./trade-close-model.component.scss']
})
export class TradeCloseModelComponent implements OnInit {
  constructor(public modalController: ModalController ) { }

  ngOnInit() {
  }
  close() {
    this.modalController.dismiss(null);
  }


}
