<div class="drawer-header">
  <div class="header-container">
    <div class="back-to">
      <a (click)="cancel()"><ion-icon name="chevron-back-outline"></ion-icon>Back to alerts </a>
      <ion-buttons slot="end">
        <ion-button (click)="cancel()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
  </div>
</div>

<ng-container>
  <div class="page-heading">
    <h2>{{ notificationData.symbol }} Alerts</h2>
  </div>
  <ion-segment [(ngModel)]="selectedSegment"> </ion-segment>
  <ng-container *ngIf="selectedSegment === 'active'">
    <div *ngFor="let list of notificationData" class="list-container">
      <div *ngIf="list.type != 'on_date'">
        <div class="list-data list-data-custom">
          <div class="symbol">
            <ion-icon name="bar-chart-outline"></ion-icon>
          </div>
          <div class="right-data-block">
            <div class="title">Stock Price Alert</div>
            <div class="alert-info">
              Alert will be triggered when price reaches {{ list.triggerPrice | currency }}
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="list.type === 'on_date'">
        <div class="list-data list-data-custom">
          <div class="symbol">
            <ion-icon name="time-outline"></ion-icon>
          </div>
          <div class="right-data-block">
            <div class="title">Date/Timer Alert</div>
            <div class="alert-info">
              Alert will be triggered on reaches {{ list.triggerDate | date : 'medium' }}
            </div>
          </div>
        </div>
      </div>
      <div class="edit">
        <button ion-button icon-only (click)="toggleView(list)" class="chat-menu-btn">
          <ion-icon name="create-outline"></ion-icon>
        </button>
      </div>
    </div>
    <div *ngIf="notificationData?.length === 0" class="empty-alert-list">
      <ion-icon name="notifications-off-outline"></ion-icon>
      <span>You don't have any alerts</span>
    </div>
  </ng-container>
</ng-container>
