<ion-content class="ion-padding" appScrollbar>
  <div class="general-main" *ngIf="!isToolAccess">
    <div class="no-data-container">
      <div class="no-data">
        <img src="/assets/icon/locked.png" alt="" />
        <p *ngIf="!isToolAccess && !lockedPageContent">
          Please be patience, reviewing tool access.
        </p>
        <p
          *ngIf="!isToolAccess && lockedPageContent"
          [innerHTML]="lockedPageContent | keepHtml"
        ></p>
      </div>
    </div>
  </div>
  <div *ngIf="isToolAccess && !lockedPageContent">
    <ion-row class="tracker-details-row">
      <ion-col size="4" class="tracker-details-col dashboard-nav">
        <div>
          <ol class="cd-breadcrumb custom-separator custom-icons">
            <li><a routerLink="/{{ role }}/dashboard">Tools</a></li>
            <li>
              <a routerLink="/{{ role }}/tools/dashboard/{{ activeToolSlug }}">Tools Dashboard</a>
            </li>
            <li class="current">
              {{ selectedStockDetails?.symbol | uppercase }}
            </li>
          </ol>
        </div>
      </ion-col>
      <ion-col size="6" class="tracker-details-col dashboard-search">
        <div class="loading-spinner" *ngIf="isLoading"></div>
        <ion-searchbar
          class="searchbar-green"
          search-icon="search-outline"
          debounce="1000"
          id="search"
          placeholder="Quote Lookup"
          (ionChange)="onSearchLookupNew($event)"
          (keydown)="handleKeyDown($event)"
          [value]="
            selectedLookup.symbol && selectedLookup.name
              ? selectedLookup.symbol + ' - ' + selectedLookup.name
              : ''
          "
        >
        </ion-searchbar>
        <div
          class="taglist-search"
          *ngIf="
            !isLoading &&
            lookupListVisibility &&
            !selectedLookup?.symbol &&
            searchResult &&
            searchResult.length > 0
          "
        >
          <span class="close" (click)="hideLookupList()">&#10005;</span>
          <p
            *ngFor="let item of searchResult; let i = index"
            (click)="addLookup(item.symbol)"
            [class.selected]="i === highlightedIndex"
            tabindex="0"
          >
            {{ item.symbol }} - {{ item.name }}
          </p>
        </div>
      </ion-col>
    </ion-row>
    <div>
      <div class="page-heading">
        <span
          *ngIf="context === 'dashboard'"
          class="back-trade"
          routerLink="/{{ role }}/tools/dashboard/{{ activeToolSlug }}"
        >
          < Back To Tool Dashboard</span
        >
        <span *ngIf="context === 'watchlist'" class="back-trade" (click)="goBack()">
          < Back To Watchlists
        </span>
        <span *ngIf="context === 'setup-scanner'" class="back-trade" (click)="goBack()">
          < Back To Setup-Scanner
        </span>
        <ion-button fill="outline" *ngIf="!watchlistCount" (click)="toggleButtonColor()">
          <ion-icon slot="start" name="add-outline"></ion-icon> Add To Watchlist
        </ion-button>
        <ion-button
          class="watchlist-btn"
          fill="outline"
          *ngIf="watchlistCount"
          (click)="toggleButtonColor()"
        >
          Watchlist
          <span class="number-round">{{ watchlistCount }}</span>
        </ion-button>
      </div>
      <ion-row class="tracker-details-row dashboad-row">
        <ion-col
          size-xs="12"
          size-sm="12"
          size-md="12"
          size-xl="12"
          size-lg="12"
          class="tracker-details-col"
        >
          <div class="share-details search-details">
            <ion-card class="ion-no-margin card-container">
              <ion-card-content class="ion-no-padding share-details-header">
                <div class="page-heading custom-style">
                  <div class="heading-area">
                    <div class="action" *ngIf="selectedStockDetails?.stockUrl">
                      <span class="logo"
                        ><img [src]="selectedStockDetails?.stockUrl" alt="" srcset=""
                      /></span>
                    </div>
                    <div class="action" *ngIf="iframeSrc && !selectedStockDetails?.stockUrl">
                      <span class="logo log-dynamic"
                        ><img [src]="iframeSrc" alt="" srcset=""
                      /></span>
                    </div>
                    <div class="title-wrap" *ngIf="selectedStockDetails?.symbol">
                      <h2 style="color: black !important; font-weight: 500">
                        {{ selectedStockDetails?.shortName }}({{ selectedStockDetails?.symbol }})
                      </h2>
                      <h4>
                        {{ selectedStockDetails?.fullExchangeName }}
                      </h4>
                    </div>
                  </div>
                  <div
                    class="ion-flex-container ion-align-items-left ion-flex-column custom-rate-style"
                  >
                    <div class="custom-size">
                      <span class="font-custom-size"
                        >${{ selectedStockDetails?.regularMarketPrice }}</span
                      >
                    </div>
                    <div class="close-at-wrap">
                      <div class="close-symbol" *ngIf="selectedStockDetails?.regularMarketPrice">
                        <ng-container *ngIf="selectedStockDetails?.regularMarketChangePercent > 0">
                          <ion-icon
                            name="caret-up-outline"
                            color="primary"
                            style="font-size: 20px"
                          ></ion-icon>
                        </ng-container>
                        <ng-container *ngIf="selectedStockDetails?.regularMarketChangePercent < 0">
                          <ion-icon
                            name="caret-down-outline"
                            color="danger"
                            style="font-size: 20px"
                          ></ion-icon>
                        </ng-container>
                        <span
                          class="custom-size2"
                          [ngClass]="
                            getHeaderClassTodayChange({
                              selectedStockDetails: selectedStockDetails
                            })
                          "
                        >
                          {{ selectedStockDetails?.regularMarketChange | number : '1.2-2' }}
                        </span>
                        <span
                          [ngClass]="
                            getHeaderClassTodayChange({
                              selectedStockDetails: selectedStockDetails
                            })
                          "
                          >({{
                            selectedStockDetails?.regularMarketChangePercent | number : '1.2-2'
                          }}%)
                        </span>
                        <span class="text-day">Today</span>
                      </div>
                    </div>

                    <div class="close-at-wrap">
                      <div class="close-symbol" *ngIf="selectedStockDetails?.preMarketPrice">
                        <ng-container *ngIf="selectedStockDetails?.preMarketChangePercent > 0">
                          <ion-icon
                            name="caret-up-outline"
                            color="primary"
                            style="font-size: 20px"
                          ></ion-icon>
                        </ng-container>
                        <ng-container *ngIf="selectedStockDetails?.preMarketChangePercent < 0">
                          <ion-icon
                            name="caret-down-outline"
                            color="danger"
                            style="font-size: 20px"
                          ></ion-icon>
                        </ng-container>
                        <span
                          class="custom-size2"
                          [ngClass]="
                            getHeaderClassPreChange({
                              selectedStockDetails: selectedStockDetails
                            })
                          "
                        >
                          {{ selectedStockDetails?.preMarketChange | number : '1.2-2' }}
                        </span>
                        <span
                          [ngClass]="
                            getHeaderClassPreChange({
                              selectedStockDetails: selectedStockDetails
                            })
                          "
                          >({{ selectedStockDetails?.preMarketChangePercent | number : '1.2-2' }}%)
                        </span>
                        <span class="text-day"
                          >After Hours
                          <ion-icon class="close-symbol-icon" name="sunny"></ion-icon>
                        </span>
                      </div>
                    </div>

                    <div class="close-at-wrap">
                      <div class="close-symbol" *ngIf="selectedStockDetails?.postMarketPrice">
                        <ng-container *ngIf="selectedStockDetails?.postMarketChangePercent > 0">
                          <ion-icon
                            name="caret-up-outline"
                            color="primary"
                            style="font-size: 20px"
                          ></ion-icon>
                        </ng-container>
                        <ng-container *ngIf="selectedStockDetails?.postMarketChangePercent < 0">
                          <ion-icon
                            name="caret-down-outline"
                            color="danger"
                            style="font-size: 20px"
                          ></ion-icon>
                        </ng-container>
                        <span
                          class="custom-size2"
                          [ngClass]="
                            getHeaderClassPostChange({
                              selectedStockDetails: selectedStockDetails
                            })
                          "
                        >
                          {{ selectedStockDetails?.postMarketChange | number : '1.2-2' }}
                        </span>
                        <span
                          [ngClass]="
                            getHeaderClassPostChange({
                              selectedStockDetails: selectedStockDetails
                            })
                          "
                          >({{ selectedStockDetails?.postMarketChangePercent | number : '1.2-2' }}%)
                        </span>
                        <span class="text-day"
                          >After Hours
                          <ion-icon name="moon" class="close-symbol-icon"></ion-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <ion-segment (ionChange)="segmentChanged($event)" [value]="defaultView">
                  <ion-segment-button class="css-font" value="stockInfo">
                    <ion-label> Stock Info </ion-label>
                  </ion-segment-button>
                  <ion-segment-button class="css-font" value="aboutStock">
                    <ion-label> About Stock </ion-label>
                  </ion-segment-button>
                  <ion-segment-button
                    class="css-font"
                    value="latestNews"
                    (click)="onRecentNews(selectedStockDetails?.symbol)"
                  >
                    <ion-label> Latest News </ion-label>
                  </ion-segment-button>
                  <ion-segment-button class="css-font" value="tradeHistory">
                    <ion-label> Trade History </ion-label>
                  </ion-segment-button>
                  <ion-segment-button class="css-font" value="alert" (click)="getAlertList()">
                    <ion-label> Alerts </ion-label>
                  </ion-segment-button>
                  <ion-segment-button
                    class="css-font"
                    value="notes"
                    (click)="getSymbolWiseNotes(selectedStockDetails?.symbol)"
                  >
                    <ion-label> Notes </ion-label>
                  </ion-segment-button>
                </ion-segment>
              </ion-card-content>
            </ion-card>
          </div>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="segment === 'stockInfo'" class="tracker-details-row dashboad-row tracker-row">
        <ion-col
          size-xs="12"
          size-sm="12"
          size-md="12"
          size-xl="8"
          size-lg="8"
          class="tracker-details-col"
        >
          <div class="share-details search-details content-block">
            <ion-card class="ion-no-margin card-container">
              <ion-card-content class="ion-no-padding custom-style">
                <div class="item-block item-scrollbar-block">
                  <div class="tradingview-widget-container">
                    <div #hotlistsymbol class="tradingview-widget-container__widget"></div>
                    <div class="tradingview-widget-copyright">
                      <a href="https://in.tradingview.com/" rel="noopener" target="_blank"></a>
                    </div>
                  </div>
                </div>
              </ion-card-content>
            </ion-card>
          </div>
        </ion-col>
        <ion-col
          size-xs="12"
          size-sm="12"
          size-md="12"
          size-xl="4"
          size-lg="4"
          class="tracker-details-col"
        >
          <div class="account-details ion-no-padding account-block">
            <ion-card class="ion-no-margin rounded-area" style="height: 100%">
              <h3 style="margin: 15px 0px 0px 19px">Stock Details</h3>
              <ion-card-content class="ion-no-padding">
                <ion-list lines="full" class="list-ticker-portfolio-details">
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Weekly Options</span>
                        <span
                          class="weekly-option"
                          [ngClass]="{
                            yes: selectedStockDetails?.isWeeklyStock,
                            no: !selectedStockDetails?.isWeeklyStock
                          }"
                        >
                          <ion-icon
                            [name]="
                              selectedStockDetails?.isWeeklyStock
                                ? 'checkmark-circle'
                                : 'close-circle'
                            "
                          ></ion-icon>
                          {{ selectedStockDetails?.isWeeklyStock ? 'Yes' : 'No' }}
                        </span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Prev. Close</span>
                        <span>{{ selectedStockDetails?.regularMarketPreviousClose }}</span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Open</span>
                        <span>{{ selectedStockDetails?.regularMarketOpen }}</span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Day Range</span>
                        <span>{{ selectedStockDetails?.regularMarketDayRange }}</span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">52 Week Range</span>
                        <span>{{ selectedStockDetails?.fiftyTwoWeekRange }}</span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Volume</span>
                        <span>{{
                          selectedStockDetails?.regularMarketVolume | number : '1.0-0'
                        }}</span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Avg. volume</span>
                        <span>{{
                          selectedStockDetails?.averageDailyVolume10Day | number : '1.0-0'
                        }}</span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Market Cap</span>
                        <span>{{ convertMarketCapToWords(selectedStockDetails?.marketCap) }}</span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Beta (5Y Monthly)</span>
                        <span>{{ convertMarketCapToWords(selectedStockDetails?.marketCap) }}</span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Earnings Date</span>
                        <span>{{
                          selectedStockDetails?.earningsTimestampStart * 1000
                            | date : 'MMM dd, yyyy'
                        }}</span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item lines="none">
                    <div
                      class="detail-container label"
                      [ngClass]="{
                        confirmed: selectedStockDetails?.earning_confirmed,
                        'not-confirmed': !selectedStockDetails?.earning_confirmed
                      }"
                    >
                      <div class="details">
                        <span class="value-opacity">Earnings Confirmed/Unconfirmed</span>
                        <span
                          class="earnings-status"
                          [ngClass]="{
                            confirmed: selectedStockDetails?.earning_confirmed,
                            'not-confirmed': !selectedStockDetails?.earning_confirmed
                          }"
                        >
                          <ion-icon
                            [name]="
                              selectedStockDetails?.earning_confirmed
                                ? 'checkmark-outline'
                                : 'close-outline'
                            "
                          ></ion-icon>
                          {{
                            selectedStockDetails?.earning_confirmed ? 'Confirmed' : 'Unconfirmed'
                          }}
                        </span>
                        <div
                          *ngIf="selectedStockDetails?.earning_confirmed"
                          class="earnings-subtext"
                        >
                          Before the open
                        </div>
                        <div
                          *ngIf="!selectedStockDetails?.earning_confirmed"
                          class="earnings-subtext"
                        >
                          After the close
                        </div>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Forward Dividend & Yield</span>
                        <span *ngIf="selectedStockDetails?.dividendYield"
                          >{{ selectedStockDetails?.dividendRate?.toFixed(2) }}({{
                            selectedStockDetails?.dividendYield
                          }})</span
                        >
                        <span *ngIf="!selectedStockDetails?.dividendYield">-</span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Ex-Dividend Date</span>
                        <span>{{
                          selectedStockDetails?.dividendDate * 1000 | date : 'MMM dd, yyyy'
                        }}</span>
                        <span *ngIf="!selectedStockDetails?.dividendDate">-</span>
                      </div>
                    </div>
                  </ion-item>
                </ion-list>
              </ion-card-content>
            </ion-card>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="tracker-details-row dashboad-row">
        <ion-col
          size-xs="12"
          size-sm="12"
          size-md="12"
          size-xl="12"
          size-lg="12"
          class="tracker-details-col"
        >
          <div class="share-details search-details">
            <ion-card class="ion-no-margin card-container">
              <ion-card-content class="ion-no-padding width-css">
                <ion-list
                  class="animate__animated animate__slideInUp animate__fast tab-content width-css about-stock"
                  *ngIf="segment === 'aboutStock'"
                >
                  <ion-item class="no-border">
                    <span>{{ selectedStockDetails?.summary }}</span>
                  </ion-item>
                </ion-list>

                <ion-list
                  class="animate__animated animate__slideInUp animate__fast latest-news"
                  *ngIf="segment === 'latestNews'"
                >
                  <ion-col>
                    <div class="card-container">
                      <div class="custom-card custom-card-block news-wrap-section">
                        <div class="card-content">
                          <div class="product-wrapper">
                            <div>
                              <div
                                class="loading-spinner news-wrap-section"
                                *ngIf="isLoading"
                              ></div>
                              <ngx-datatable
                                #externalSpendTable
                                class="material body-cell-stacked"
                                [rows]="selectedNews"
                                [columnMode]="'force'"
                                [rowHeight]="'auto'"
                                [headerHeight]="50"
                                [footerHeight]="40"
                                [externalPaging]="true"
                                [scrollbarH]="true"
                                [count]="page.totalElements"
                                [offset]="page.offset"
                                [limit]="page.size"
                                *ngIf="!isLoading"
                              >
                                <ngx-datatable-column [sortable]="false">
                                  <ng-template
                                    let-row="row"
                                    let-title="row.title"
                                    ngx-datatable-cell-template
                                  >
                                    <span>
                                      {{ title }}
                                    </span>
                                  </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [sortable]="false">
                                  <ng-template
                                    let-row="row"
                                    let-link="row.link"
                                    let-pubDate="row.pubDate"
                                    ngx-datatable-cell-template
                                  >
                                    <span class="recent-news-name" (click)="link && openLink(link)">
                                      Source
                                    </span>
                                    <span class="pub-date"> • {{ pubDate | date : 'h:mm a' }}</span>
                                  </ng-template>
                                </ngx-datatable-column>
                              </ngx-datatable>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ion-col>
                </ion-list>
                <ion-list
                  class="animate__animated animate__slideInUp animate__fast tab-content width-css"
                  *ngIf="segment === 'tradeHistory'"
                >
                  <ion-item class="width-css alert-heading-item">
                    <div class="page-heading">
                      <h2>Trade History</h2>
                    </div>
                  </ion-item>
                  <div class="trade-history-wrap trade-history-section">
                    <div class="trade-history-block">
                      <div class="trader-data">
                        <span>Trade Type:</span>
                        <span class="value">MIT</span>
                      </div>
                      <div class="trader-data">
                        <span>Date Opened :</span>
                        <span class="value">12 Sep 24</span>
                      </div>
                      <div class="trader-data">
                        <span>Trade Duration :</span>
                        <span class="value">40 Days</span>
                      </div>
                      <div class="trader-data">
                        <span>POS. P/L :</span>
                        <span class="value red-text">-$62.00</span>
                      </div>
                      <div class="trader-data">
                        <span>Notes :</span>
                        <span class="value">
                          <ion-icon slot="start" name="document-text-outline"></ion-icon> 1
                          Note</span
                        >
                      </div>
                      <div class="trader-data">
                        <span>Status :</span>
                        <ion-button class="open-btn">Open</ion-button>
                      </div>
                    </div>
                    <div class="trade-history-block">
                      <div class="trader-data">
                        <span>Trade Type:</span>
                        <span class="value">SOB</span>
                      </div>
                      <div class="trader-data">
                        <span>Date Opened :</span>
                        <span class="value">12 Sep 24</span>
                      </div>
                      <div class="trader-data">
                        <span>Trade Duration :</span>
                        <span class="value">40 Days</span>
                      </div>
                      <div class="trader-data">
                        <span>POS. P/L :</span>
                        <span class="value green-text">$62.00</span>
                      </div>
                      <div class="trader-data">
                        <span>Notes :</span>
                        <span class="value">
                          <ion-icon slot="start" name="document-text-outline"></ion-icon> 1
                          Note</span
                        >
                      </div>
                      <div class="trader-data">
                        <span>Status :</span>
                        <ion-button class="close-btn">Closed</ion-button>
                      </div>
                    </div>
                    <div class="trade-history-block">
                      <div class="trader-data">
                        <span>Trade Type:</span>
                        <span class="value"> Money Press Method</span>
                      </div>
                      <div class="trader-data">
                        <span>Date Opened :</span>
                        <span class="value">12 Sep 24</span>
                      </div>
                      <div class="trader-data">
                        <span>Trade Duration :</span>
                        <span class="value">40 Days</span>
                      </div>
                      <div class="trader-data">
                        <span>POS. P/L :</span>
                        <span class="value red-text">-$62.00</span>
                      </div>
                      <div class="trader-data">
                        <span>Notes :</span>
                        <span class="value">
                          <ion-icon slot="start" name="document-text-outline"></ion-icon> 1
                          Note</span
                        >
                      </div>
                      <div class="trader-data">
                        <span>Status :</span>
                        <ion-button class="open-btn">Open</ion-button>
                      </div>
                    </div>
                    <div class="trade-history-block">
                      <div class="current-view" *ngIf="isActiveCurrentView">Currently Viewing</div>
                      <div class="trader-data">
                        <span>Date Opened :</span>
                        <span class="value">12 Sep 24</span>
                      </div>
                      <div class="trader-data">
                        <span>Trade Duration :</span>
                        <span class="value">40 Days</span>
                      </div>
                      <div class="trader-data">
                        <span>POS. P/L :</span>
                        <span class="value green-text">$62.00</span>
                      </div>
                      <div class="trader-data">
                        <span>Notes :</span>
                        <span class="value">
                          <ion-icon slot="start" name="document-text-outline"></ion-icon> 1
                          Note</span
                        >
                      </div>
                      <div class="trader-data">
                        <span>Status :</span>
                        <ion-button class="close-btn">Closed</ion-button>
                      </div>
                    </div>
                  </div>
                </ion-list>

                <ion-list
                  class="animate__animated animate__slideInUp animate__fast tab-content width-css"
                  *ngIf="segment === 'alert'"
                >
                  <ion-item class="alert-heading-item ion-no-padding width-css">
                    <div class="alert-heading">
                      <div class="page-heading">
                        <h2>Custom Alerts You've Set</h2>
                      </div>
                      <div class="">
                        <ion-button
                          class="color-css1 create-own"
                          fill="outline"
                          (click)="openRightMenuAlert('add')"
                        >
                          <ion-icon name="add-outline" fill="outline"></ion-icon> Add Alert
                        </ion-button>
                        <ion-button
                          color="primary"
                          fill="solid"
                          (click)="openRightMenuNotification()"
                          ><ion-icon name="notifications-outline"></ion-icon>Alert
                          History</ion-button
                        >
                      </div>
                    </div>
                  </ion-item>

                  <ion-row>
                    <ion-segment
                      (ionChange)="segmentChangedalert($event)"
                      [value]="defaultAlertType"
                      class="segmentDesign"
                    >
                      <ion-segment-button value="stockInfoAlert"
                        ><ion-label>
                          Stock Price Alert
                          <span class="badge">{{ stockPriceAlerts?.length }}</span>
                        </ion-label>
                      </ion-segment-button>
                      <ion-segment-button value="dateTimeAlerts"
                        ><ion-label
                          >Date Time Alert <span class="badge">{{ dateTimeAlerts?.length }}</span>
                        </ion-label>
                      </ion-segment-button>
                    </ion-segment>
                    <div class="alert-wrap">
                      <ion-list *ngIf="defaultAlertType == 'stockInfoAlert'">
                        <div class="card-container">
                          <div class="custom-card custom-card-block">
                            <div class="card-content">
                              <div
                                class="product-wrapper alert-product"
                                *ngFor="let alertList of stockPriceAlerts"
                              >
                                <div
                                  class="alert-data-section"
                                  [ngClass]="{ 'gray-row': alertList.status !== 'active' }"
                                >
                                  <div class="alert-left">
                                    <div
                                      *ngIf="alertList.conditionType == 'move_above'"
                                      class="alert-data"
                                      [ngClass]="{ 'green-color': alertList.status === 'active' }"
                                    >
                                      <p>
                                        Price moves above
                                        <span>$ {{ alertList.triggerPrice }}</span>
                                      </p>
                                    </div>
                                    <div
                                      *ngIf="alertList.conditionType == 'move_below'"
                                      class="alert-data"
                                      [ngClass]="{ 'red-color': alertList.status === 'active' }"
                                    >
                                      <p>
                                        Price moves below
                                        <span>$ {{ alertList.triggerPrice }}</span>
                                      </p>
                                    </div>
                                  </div>
                                  <div class="alerts-right-section">
                                    <ion-item lines="none" class="ion-no-padding width-css">
                                      <ion-toggle
                                        lines="none"
                                        class="ion-no-padding"
                                        justify="end"
                                        [checked]="alertList.status == 'active' ? true : false"
                                        (ionChange)="onAlertCreateSubmit(alertList)"
                                      ></ion-toggle>
                                    </ion-item>
                                    <div class="alerts-btn">
                                      <ion-buttons>
                                        <ion-button
                                          fill="clear"
                                          (click)="toggleDropdown($event, alertList)"
                                        >
                                          <ion-icon name="ellipsis-vertical"> </ion-icon>
                                        </ion-button>
                                      </ion-buttons>
                                      <div
                                        class="dropdown-menu"
                                        *ngIf="isDropdownOpen && selectedRow === alertList"
                                      >
                                        <ion-item
                                          id="watchlist-list-area"
                                          lines="none"
                                          class="ion-no-padding width-css"
                                          (click)="openRightMenuAlert('edit', alertList)"
                                          >Edit Alert</ion-item
                                        >
                                        <ion-item
                                          id="watchlist-list-area"
                                          lines="none"
                                          class="ion-no-padding width-css"
                                          (click)="showAlertDelete(alertList)"
                                          >Delete Alert</ion-item
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ion-list>
                      <ion-list *ngIf="defaultAlertType == 'dateTimeAlerts'">
                        <div class="card-container">
                          <div class="custom-card custom-card-block">
                            <div class="card-content">
                              <div
                                class="product-wrapper alert-product"
                                *ngFor="let alertDate of dateTimeAlerts"
                              >
                                <div
                                  class="alert-data-section"
                                  [ngClass]="{ 'gray-row': alertDate.status !== 'active' }"
                                >
                                  <div class="alert-left">
                                    <div
                                      class="alert-data"
                                      [ngClass]="{ 'green-color': alertDate.status === 'active' }"
                                    >
                                      <p>
                                        Alert set for
                                        {{ alertDate.triggerDate | date : 'MMM d, yyyy, h:mm a' }}.
                                      </p>
                                    </div>
                                  </div>
                                  <div class="alerts-right-section">
                                    <ion-item lines="none" class="ion-no-padding width-css">
                                      <ion-toggle
                                        lines="none"
                                        class="ion-no-padding"
                                        justify="end"
                                        [checked]="alertDate.status == 'active' ? true : false"
                                        (click)="onAlertCreateSubmit(alertDate)"
                                      ></ion-toggle>
                                    </ion-item>
                                    <div class="alerts-btn">
                                      <ion-button
                                        fill="clear"
                                        (click)="toggleDropdown($event, alertDate)"
                                      >
                                        <ion-icon name="ellipsis-vertical"></ion-icon>
                                      </ion-button>
                                      <div
                                        class="dropdown-menu"
                                        *ngIf="isDropdownOpen && selectedRow === alertDate"
                                      >
                                        <ion-item
                                          id="watchlist-list-area"
                                          lines="none"
                                          class="ion-no-padding width-css"
                                          (click)="openRightMenuAlert('edit', alertDate)"
                                          >Edit Alert</ion-item
                                        >
                                        <ion-item
                                          id="watchlist-list-area1"
                                          lines="none"
                                          class="ion-no-padding width-css"
                                          (click)="showAlertDelete(alertDate)"
                                          >Delete Alert</ion-item
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ion-list>
                    </div>
                  </ion-row>

                  <div class="Under-construction" style="display: none">
                    <div>
                      <ion-icon name="construct-outline"></ion-icon>
                      <h1>Under construction</h1>
                    </div>
                  </div>
                </ion-list>
                <ion-list
                  class="animate__animated animate__slideInUp animate__fast tab-content"
                  *ngIf="segment === 'notes'"
                >
                  <ion-item class="ion-no-padding width-css">
                    <ion-title>
                      <div class="page-heading">
                        <h2>Notes</h2>
                      </div>
                    </ion-title>
                    <div class="actions">
                      <div class="crypto-details-button create-own-note">
                        <ion-button
                          size="small"
                          shape="round"
                          class="color-css"
                          fill="solid"
                          (click)="openRightMenuToolNotes('create')"
                        >
                          Create Notes
                        </ion-button>
                      </div>
                    </div>
                  </ion-item>

                  <ion-col>
                    <div class="card-container">
                      <div class="custom-card custom-card-block">
                        <div class="card-content">
                          <div class="product-wrapper">
                            <div>
                              <div class="loading-spinner" *ngIf="isLoading"></div>
                              <ngx-datatable
                                #externalSpendTable
                                class="material body-cell-stacked note-section-data"
                                [rows]="selectedNotes"
                                [columnMode]="'force'"
                                [rowHeight]="'auto'"
                                [headerHeight]="0"
                                [footerHeight]="40"
                                [externalPaging]="true"
                                [scrollbarH]="true"
                                [count]="page.totalElements"
                                [offset]="page.offset"
                                [limit]="page.size"
                                *ngIf="!isLoading"
                              >
                                <ngx-datatable-column [sortable]="false">
                                  <ng-template
                                    let-row="row"
                                    let-createdAt="row.createdAt"
                                    ngx-datatable-cell-template
                                  >
                                    <span class="pub-date">
                                      {{ row.createdAt | date : 'MMM d, h:mm a' }}
                                    </span>

                                    <!-- <span class="pub-date">
                                      
                                      {{ row.createdAt | date : 'MMM d, h:mm a' }}</span
                                    > -->
                                  </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [sortable]="false">
                                  <ng-template
                                    let-row="row"
                                    let-description="row.description"
                                    ngx-datatable-cell-template
                                  >
                                    <span>
                                      {{ description }}
                                    </span>
                                  </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column [sortable]="false" [resizeable]="false">
                                  <ng-template let-row="row" ngx-datatable-cell-template>
                                    <ion-buttons slot="primary" class="ion-justify-content-end">
                                      <ion-button
                                        color="medium"
                                        fill="clear"
                                        (click)="
                                          $event.preventDefault();
                                          $event.stopPropagation();
                                          openRightMenuToolNotes('edit', row)
                                        "
                                      >
                                        <ion-icon slot="icon-only" name="create-outline"></ion-icon>
                                      </ion-button>
                                      <ion-button
                                        shape="round"
                                        color="danger"
                                        fill="clear"
                                        size="small"
                                        (click)="
                                          $event.preventDefault();
                                          $event.stopPropagation();
                                          deleteNotes(row)
                                        "
                                      >
                                        <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
                                      </ion-button>
                                    </ion-buttons>
                                  </ng-template>
                                </ngx-datatable-column>
                              </ngx-datatable>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ion-col>
                </ion-list>
              </ion-card-content>
            </ion-card>
          </div>
        </ion-col>
      </ion-row>
    </div>
  </div>
</ion-content>
