import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Page } from 'src/app/models/page';
import { StorageService } from 'src/app/services/storage.service';
import { ToastService } from 'src/app/services/toast.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';
import { WatchListCreateAddModalComponent } from './watchlist-create-add-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { TradeToolsService } from 'src/app/services/trade-tools-setting';
import { LoaderService } from 'src/app/services/loader.service';
import { ClimateService } from 'src/app/services/climate.service';
import { ClimateDetailsComponent } from './climate-details/climate-details.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { debounceTime } from 'rxjs/operators';
import { RecentsAlertsComponent } from '../alerts-details/recent-alerts/recents-alerts.component';

@Component({
  selector: 'app-tool-dashboard',
  templateUrl: './tool-dashboard.component.html',
  styleUrls: ['./tool-dashboard.component.scss'],
})
export class ToolDashboardComponent implements OnInit {
  @Input() positionProps: any;
  @Input() notesData: any;
  @ViewChild('compassContainer', { static: false }) container: ElementRef;
  role: any;
  rows = [];
  username;
  public tag = [];
  public page = new Page();
  listOfLookup: any[] = [];
  public searchText: string = null;
  lookupListVisibility: boolean = false;
  showDetails: boolean = false;
  searchResult: any[];
  selectedStockDetails: any;
  EarningsStockDetails: any;
  highlightedIndex: number = -1;
  public selectedNews: [] = [];
  public selectedNotes: [] = [];
  public segment: string = 'stockTrade';
  public arr = new Array(25);
  public isToolAccess = false;
  public lockedPageContent: null;
  slug: string;
  iframeSrc!: SafeResourceUrl;
  [x: string]: any;
  showData: any;
  climateData: any;
  isLoading = true;
  isLoadingEaring = true;
  isLoadingSearch = false;
  isLoadingnews = true;
  symbol: any;
  alertAllData: any;
  watchList: any;
  selectedLookup: {
    name: string;
    symbol: string;
    exch: string;
  } = {
    name: '',
    symbol: '',
    exch: '',
  };
  buttonStateGroup = {
    is_watch_list: false,
    title: 'Add To Watchlist',
    icon: 'add-outline',
    isButtonActive: false,
    color: 'dark',
  };
  constructor(
    public storageServ: StorageService,
    private toolsApi: ToolServiceService,
    private cdr: ChangeDetectorRef,
    private toastServ: ToastService,
    public modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private menuService: commonRightMenuService,
    private tradeToolsService: TradeToolsService,
    public loaderService: LoaderService,
    private climateService: ClimateService,
    private sanitizer: DomSanitizer
  ) {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams.symbol) {
        this.selectedLookup.symbol = queryParams.symbol;
        this.onStockDetails(queryParams.symbol);
      }
    });
    this.lockedPageContent = null;
    this.role = this.storageServ.get('role');
    this.tag = this.storageServ.get('tags') ? JSON.parse(this.storageServ.get('tags')) : [];
    this.activeToolSlug = this.activatedRoute?.snapshot.params.id;
    if (this.activeToolSlug) {
      this.tradeToolsService
        .getToolDetails(this.activeToolSlug, 'true')
        .subscribe((toolResponse: any) => {
          const { tools: toolDetails = null } = toolResponse.data;
          if (toolDetails) {
            this.storageServ.set('toolId', JSON.stringify(toolDetails));
            this.handletoolInit(toolDetails);
          }
        });
    }
  }

  ngOnInit() {
    this.onRecentNews(this.selectedStockDetails?.symbol);
    /* this.onGetLookup(); */
    this.getUserDetails();
    this.getEarningsStockDetails();
    this.getClimateData();
    this.getAlertList();
  }

  async showRecentsAlerts(alertData) {
    const modal = await this.modalController.create({
      cssClass: 'recent-alerts small-modal',
      component: RecentsAlertsComponent,
      componentProps: { alertData, activeToolSlug: this.activeToolSlug },
    });
    modal.onDidDismiss().then(({ data }) => {});
    return await modal.present();
  }
  getClimateData() {
    this.isLoadingData = true;
    this.climateService.getAllClimate().subscribe((response: any) => {
      if (response && response?.data) {
        this.climateData = response?.data?.climates;
        const value = this.climateData[0]?.value;
        this.isLoadingData = false;
        const dynamicUrl = `https://tradersedgenetwork.com/dynamic_image_creater/guage/combine-image.html?value=${value}`;
        this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(dynamicUrl);
      }
    });
  }

  getUserDetails() {
    const user: any = JSON.parse(this.storageServ.get('userData') || '{}');
    if (user && Object.keys(user).length) {
      this.username = user.firstName || '';
    }
  }

  toggleButtonColor() {
    if (this.buttonStateGroup.is_watch_list) {
      this.removeWatchList();
    } else {
      this.addToWatchlist();
    }
  }

  async showClimateDetails() {
    const modal = await this.modalController.create({
      cssClass: 'custom-climat-popup',
      component: ClimateDetailsComponent,
    });

    modal.onDidDismiss().then(({ data }) => {});
    return await modal.present();
  }

  removeWatchList() {
    this.toolsApi
      .removeFromWatchList(this.selectedStockDetails?.symbol)
      .subscribe((response: any) => {
        if (response) {
          this.toastServ.presentToast(response.message, 'success');
          this.onStockDetails(this.selectedStockDetails?.symbol);
        }
      });
  }
  onClearSearch() {
    this.searchText = null;
  }

  onGetLookup() {
    this.toolsApi.getCountryWiseStock('usa').subscribe((res: any) => {
      if (res && res?.data?.length) {
        this.listOfLookup = res.data;
      }
    });
  }

  onListForSymbol(searchText: string, disableHttpLoader = 'true') {
    this.isLoading = true;
    if (searchText) {
      this.toolsApi.getTickerSymbol(searchText, disableHttpLoader).subscribe((response: any) => {
        if (response && response?.data) {
          this.listOfLookup = response?.data;
          this.onSearchLookup(searchText);
          this.isLoading = false;
        }
      });
    } else {
      this.listOfLookup = [];
      this.isLoading = false;
    }
  }

  back() {
    window.location.reload();
  }

  onStockDetails(symbol) {
    this.toolsApi.getStockDetails(symbol).subscribe((response: any) => {
      if (response && response?.data) {
        this.selectedStockDetails = response?.data?.stockData;
        this.changeButtonEvent();
      }
    });
    this.loadSymbolGraph();
  }

  openLink(link: string): void {
    window.open(link, '_blank');
  }

  onSearchLookup(event: string) {
    this.lookupListVisibility = true;
    const trimmedSearchValue = event;
    if (trimmedSearchValue.length > 0) {
      const exactMatches = [];
      const otherMatches = [];
      for (const item of this.listOfLookup) {
        const value = `${item.symbol} - ${item.name}`.toLowerCase();
        if (value.startsWith(trimmedSearchValue)) {
          exactMatches.push(item);
        } else if (value.includes(trimmedSearchValue)) {
          otherMatches.push(item);
        }
      }
      this.searchResult = [...exactMatches, ...otherMatches];
      this.highlightedIndex = -1;
      this.isLoadingSearch = false;
    } else {
      this.lookupListVisibility = false;
      this.selectedLookup = {
        symbol: '',
        name: '',
        exch: '',
      };
    }
  }

  onSearchLookupNew(event: CustomEvent) {
    this.lookupListVisibility = true;
    const trimmedSearchValue = event.detail.value.trim().toLowerCase();
    this.onListForSymbol(trimmedSearchValue, 'true');
    this.lookupListVisibility = false;
  }

  async viewAlert(mode) {
    let watchListProps: any = {
      mode,
      componentType: 'TickerAlertsComponent',
    };
    this.menuService.openRightMenu(watchListProps);
  }

  hideLookupList() {
    this.lookupListVisibility = false;
    this.selectedLookup = {
      name: '',
      symbol: '',
      exch: '',
    };
  }

  handleKeyDown(event: KeyboardEvent) {
    this.isLoadingSearch = true;
    if (this.lookupListVisibility && this.searchResult.length > 0) {
      switch (event.key) {
        case 'ArrowDown':
          if (this.highlightedIndex < this.searchResult.length - 1) {
            this.highlightedIndex++;
          }
          event.preventDefault();
          break;
        case 'ArrowUp':
          if (this.highlightedIndex > 0) {
            this.highlightedIndex--;
          }
          event.preventDefault();
          break;
        case 'Enter':
          if (this.highlightedIndex >= 0) {
            this.addLookup(this.searchResult[this.highlightedIndex].symbol);
          } else if (this.searchResult.length > 0) {
            this.addLookup(this.searchResult[0].symbol);
          }
          event.preventDefault();
          break;
      }
    }
  }
  addLookup(symbol: string) {
    this.hideLookupList();
    this.onClearSearch();
    this.toolsApi.setContext('dashboard');
    this.router.navigate([
      `/${this.role}/tools/dashboard/${this.activeToolSlug}/stock-info/${symbol}`,
    ]);
  }

  // ngAfterViewInit() {
  //   this.loadSymbolGraph();
  // }

  /*  loadSymbolGraph() {
    const hotlistSymbolElement = this?.hotlistsymbol?.nativeElement;
    if (hotlistSymbolElement) hotlistSymbolElement.innerHTML = '';
    let stockSymbol = this.selectedLookup.exchange + ':' + this.selectedLookup.symbol;
    const script2 = this._renderer2.createElement('script');
    script2.type = `text/javascript`;
    script2.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
    script2.text = `
    {
      "autosize": true,
      "symbol": "${stockSymbol}",
     "interval": "D",
      "timezone": "Etc/UTC",
      "theme": "light",
      "style": "1",
      "locale": "in",
      "enable_publishing": false,
      "withdateranges": true,
      "allow_symbol_change": false,
      "calendar": false,
      "show_popup_button": true,
      "popup_width": "1000",
      "popup_height": "650",
      "support_host": "https://www.tradingview.com"
    `;
    hotlistSymbolElement?.appendChild(script2);
  } */

  convertMarketCapToWords(marketCap: number): string {
    if (!marketCap && marketCap !== 0) return '';
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const magnitude = Math.floor(Math.log10(Math.abs(marketCap)) / 3);
    const newValue = (marketCap / Math.pow(10, magnitude * 3)).toFixed(2);
    return `${newValue} ${suffixes[magnitude]}`;
  }

  getHeaderClassTodayChange({ row }): any {
    return {
      'custom-green': Number(row?.regularMarketChange) >= 0,
      'custom-red': Number(row?.regularMarketChange) < 0,
      'custom-black': Number(row?.regularMarketChange) === 0,
    };
  }

  segmentChanged(ev: any) {
    this.segment = ev.detail.value;
    if (this.segment == 'stockTrade') {
      this.cdr.detectChanges();
      this.loadSymbolGraph();
    }
  }

  onRecentNews(symbol, disableHttpLoader = 'true') {
    this.isLoadingnews = true;
    this.toolsApi.getRecentNews(symbol, disableHttpLoader).subscribe((result: any) => {
      const {
        data: { newsList = [] },
        meta,
      } = result;
      this.page = {
        offset: parseInt(meta?.page, 10) - 1,
        size: 10,
        totalElements: newsList.length,
        totalPages: 20,
      };
      this.selectedNews = newsList;
      this.isLoadingnews = false;
    });
  }

  onTradeTrackerView() {
    this.router.navigate([`/${this.role}/tools/trade-trackers/${this.activeToolSlug}`]);
  }

  onWatchListView() {
    this.router.navigate([`/${this.role}/tools/watchList/${this.activeToolSlug}`]);
  }

  onScannerView() {
    this.router.navigate([`/${this.role}/tools/setup-scanner/${this.activeToolSlug}`]);
  }

  handletoolInit(tool) {
    this.tool = tool;
    if (this.tool && this.role) {
      const { readonly = false, postAllowedRoles = [], status = null, tags } = this.tool;
      if (status && status === 'locked' && !readonly) {
        const allIdsExist = tags.some((id) => this.tag.includes(Number(id)));
        if (allIdsExist) {
          this.isToolAccess = true;
          this.lockedPageContent = null;
        } else {
          this.loaderService.HideLoader();
          this.toolTitle = tool.title;
          this.lockedPageContent = tool.lockedPageContent;
        }
      } else if (status && status === 'published') {
        this.isToolAccess = true;
        this.lockedPageContent = null;
      } else {
        if (readonly) {
          this.toolIsReadOnlyForUser = !postAllowedRoles.includes(this.userRole);
          if (this.toolIsReadOnlyForUser) {
            this.toolReadOnlyMessage =
              'The channel has been opened with specific levels of access, you are not permitted to post. For more information, please email thecrew@tradersedgenetwork.com.';
          }
        }
      }
    }
  }

  getAlertList() {
    this.toolsApi.getAllAlertData().subscribe((res) => {
      if (res.data && res.data?.alertList && res.data?.alertList.length) {
        this.alertAllData = res.data.alertList;
      }
    });
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  }

  getEarningsStockDetails() {
    this.isLoadingEaring = true;
    this.toolsApi.getWatchListStockDetails(this.searchText).subscribe((response: any) => {
      if (response && response?.data) {
        this.EarningsStockDetails = response?.data;
        this.isLoadingEaring = false;
      }
    });
  }

  onUpcomingEarningsView() {
    this.router.navigate([
      `/${this.role}/tools/setup-scanner/${this.activeToolSlug}/product/${true}`,
    ]);
  }
}
