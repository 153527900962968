import { Component, Input, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { constant } from 'lodash';
import { Page } from 'src/app/models/page';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { SetupScannerService } from 'src/app/services/setup-scanner.service';
import { ToastService } from 'src/app/services/toast.service';
import { TradeToolsService } from 'src/app/services/trade-tools-setting';
import {
  FILTERSETUPDATA,
  CHANGE,
  COUNTRY,
  DAVGCHG200,
  DAVGCHG50,
  EARNINGS,
  FILTERLIST,
  INDUSTRY,
  MARKET_CAP,
  PRICE,
  SECTOR,
  VOLUME,
  WEEKLYOPTION,
  WHIGHCHG52,
} from 'src/app/utils/constant';

@Component({
  selector: 'app-filter-setup-scanner-list',
  templateUrl: './filter-setup-scanner-list.component.html',
  styleUrls: ['./filter-setup-scanner-list.component.scss'],
})
export class FilterSetupScannerListComponent implements OnInit {
  @Input() setupScannerFilterData: any;
  showForm = false;
  isLoading = true;
  selectedMarketCap: string;
  selectedPrice: string;
  selectedPriceData: string;
  selectedMinValue: string = '5';
  selectedMaxValue: string = '10';
  selectedChange: string;
  selectedVolume: string;
  selectedWeeklyOption: string;
  selectedEarnings: string;
  selectedCountry: string;
  selectedIndustry: string;
  selectedSector: string;
  selected50DAvgChg: string;
  selected200DAvgChg: string;
  selected52WHighChg: string;
  selectedVolumeData: string;
  selectedWeeklyOptionData: string;
  selectedEarningsData: string;
  selectedMarketCapData: string;
  selectedChangeData: string;
  selectedCountryData: string;
  selectedIndustryData: string;
  selectedSectorData: string;
  selected50DAvgChgData: string;
  selected200DAvgChgData: string;
  selected52WHighChgData: string;
  filterDataObject = {};
  showFilterData: any;
  MARKET_CAP = MARKET_CAP;
  PRICE = PRICE;
  CHANGE = CHANGE;
  VOLUME = VOLUME;
  WEEKLYOPTION = WEEKLYOPTION;
  EARNINGS = EARNINGS;
  FILTERLIST = FILTERLIST;
  COUNTRY = COUNTRY;
  INDUSTRY = INDUSTRY;
  SECTOR = SECTOR;
  DAVGCHG50 = DAVGCHG50;
  DAVGCHG200 = DAVGCHG200;
  WHIGHCHG52 = WHIGHCHG52;
  FILTERSETUPDATA = FILTERSETUPDATA;
  selectedFilter: string = '';
  searchText: any;
  public page = new Page();
  rows: any;
  constructor(
    public menuController: MenuController,
    private tradeToolsService: TradeToolsService,
    private toastServ: ToastService,
    private menuService: commonRightMenuService,
    private setupScannerService: SetupScannerService
  ) {}

  ngOnInit() {
    this.setupScannerService.filterObject$.subscribe((data) => {
      if (data) {
        this.selectedPriceData = data.price;
        this.selectedPrice = this.selectedPriceData;
        this.selectedVolumeData = data.volume;
        this.selectedVolume = this.selectedVolumeData;
        this.selectedEarningsData = data.earnings;
        this.selectedEarnings = this.selectedEarningsData;
        this.selectedChangeData = data.change;
        this.selectedChange = this.selectedChangeData;
        this.selectedWeeklyOptionData =
          data.isWeeklyStock != null ? (data.isWeeklyStock === true ? 'Yes' : 'No') : null;
        this.selectedWeeklyOption = this.selectedWeeklyOptionData;
        this.selectedMarketCapData = data.marketCap;
        this.selectedMarketCap = this.selectedMarketCapData;
      }
    });
  }

  getFilterWiseShow(filter: string) {
    this.selectedFilter = filter;
    this.showForm = !this.showForm;
  }

  goBack() {
    this.showForm = false;
  }

  cancel(isNotify = 'true') {
    this.showForm = false;
    this.menuController.close();
    if (isNotify === 'true') {
      this.menuService.setCloseMenuSubject({});
    }
  }

  isAnyFilterApplied() {
    return (
      this.selectedPrice ||
      this.selectedVolume ||
      this.selectedWeeklyOption ||
      this.selectedEarnings ||
      this.selectedMarketCap ||
      this.selectedChange ||
      this.selectedCountry ||
      this.selectedIndustry ||
      this.selectedSector ||
      this.selected50DAvgChg ||
      this.selected200DAvgChg ||
      this.selected52WHighChg
    );
  }

  clearFilters() {
    let filterObject = { page: 1, pageSize: 50 };
    filterObject['price'] = null;
    this.selectedPrice = null;
    this.selectedPriceData = null;
    filterObject['volume'] = null;
    this.selectedVolume = null;
    this.selectedVolumeData = null;
    filterObject['isWeeklyStock'] = null;
    this.selectedWeeklyOption = null;
    this.selectedWeeklyOptionData = null;
    filterObject['earnings'] = null;
    this.selectedEarnings = null;
    this.selectedEarningsData = null;
    filterObject['marketcap'] = null;
    this.selectedMarketCap = null;
    this.selectedMarketCapData = null;
    filterObject['changes(%)'] = null;
    this.selectedChange = null;
    this.selectedChangeData = null;
    filterObject['country'] = null;
    this.selectedCountry = null;
    this.selectedCountryData = null;
    filterObject['industry'] = null;
    this.selectedIndustry = null;
    this.selectedIndustryData = null;
    filterObject['sector'] = null;
    this.selectedSector = null;
    this.selectedSectorData = null;
    filterObject['50DAvgChg'] = null;
    this.selected50DAvgChg = null;
    this.selected50DAvgChgData = null;
    filterObject['200DAvgChg'] = null;
    this.selected200DAvgChg = null;
    this.selected200DAvgChgData = null;
    filterObject['52WHighChg'] = null;
    this.selected52WHighChg = null;
    this.selected52WHighChgData = null;
    const filterCount = this.calculateFilterCount();
    filterObject['filterCount'] = filterCount;
    filterObject['page'] = this.page.offset + 1;
    filterObject['pageSize'] = this.page.size;
    this.filterDataObject = { ...this.filterDataObject, ...filterObject };
    this.tradeToolsService
      .setupScannerWeeklyStock(this.filterDataObject)
      .subscribe((result: any) => {
        this.menuService.setCloseMenuSubject(this.filterDataObject);
        this.showForm = false;
        this.toastServ.presentToast(result?.message, 'success');
        const { data = [], meta } = result;
        this.page = {
          offset: parseInt(meta.page, 10) - 1,
          size: meta?.perPage,
          totalElements: meta?.total,
          totalPages: meta?.pages,
        };
        this.rows = data;
        this.menuService.triggerChildResetFilter({ ...result });
      });
  }

  get appliedFilterCount(): number {
    let count = 0;
    if (this.selectedPrice) count++;
    if (this.selectedVolume) count++;
    if (this.selectedWeeklyOption) count++;
    if (this.selectedEarnings) count++;
    if (this.selectedMarketCap) count++;
    if (this.selectedChange) count++;
    return count;
  }

  applyFilter() {
    let filterObject = { page: 1, pageSize: 50 };
    if (this.selectedPrice && this.selectedPrice !== 'any' && this.selectedPrice !== 'between') {
      filterObject['price'] = this.selectedPrice;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === 'Price');
      priceFilter.currentValue = this.selectedPrice;
      priceFilter.currentValue = this.selectedPrice;
      this.selectedPriceData = priceFilter.currentValue;
    }
    if (this.selectedPrice && this.selectedPrice !== 'any' && this.selectedPrice == 'between') {
      filterObject[
        'price'
      ] = `${this.selectedPrice} ${this.selectedMinValue}-${this.selectedMaxValue}`;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === 'Price');
      priceFilter.currentValue = filterObject['price'];
      this.selectedPriceData = priceFilter.currentValue;
    }

    if (this.selectedVolume && this.selectedVolume !== 'any') {
      filterObject['volume'] = this.selectedVolume;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === 'Volume');
      priceFilter.currentValue = this.selectedVolume;
      this.selectedVolumeData = priceFilter.currentValue;
    }

    if (this.selectedWeeklyOption && this.selectedWeeklyOption !== 'any') {
      filterObject['weeklyoption'] = this.selectedWeeklyOption;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === 'WeeklyOption');
      priceFilter.currentValue = this.selectedWeeklyOption;
      this.selectedWeeklyOptionData = priceFilter.currentValue;
    }

    if (this.selectedEarnings && this.selectedEarnings !== 'any') {
      filterObject['earnings'] = this.selectedEarnings;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === 'Earnings');
      priceFilter.currentValue = this.selectedEarnings;
      this.selectedEarningsData = priceFilter.currentValue;
    }

    if (this.selectedMarketCap && this.selectedMarketCap !== 'any') {
      filterObject['marketCap'] = this.selectedMarketCap;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === 'MarketCap');
      priceFilter.currentValue = this.selectedMarketCap;
      this.selectedMarketCapData = priceFilter.currentValue;
    }

    if (this.selectedChange && this.selectedChange !== 'any') {
      filterObject['change'] = this.selectedChange;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === 'Change');
      priceFilter.currentValue = this.selectedChange;
      this.selectedChangeData = priceFilter.currentValue;
    }

    if (this.selectedCountry && this.selectedCountry !== 'any') {
      filterObject['country'] = this.selectedCountry;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === 'Country');
      priceFilter.currentValue = this.selectedCountry;
      this.selectedCountryData = priceFilter.currentValue;
    }

    if (this.selectedIndustry && this.selectedIndustry !== 'any') {
      filterObject['industry'] = this.selectedIndustry;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === 'Industry');
      priceFilter.currentValue = this.selectedIndustry;
      this.selectedIndustryData = priceFilter.currentValue;
    }

    if (this.selectedSector && this.selectedSector !== 'any') {
      filterObject['sector'] = this.selectedSector;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === 'Sector');
      priceFilter.currentValue = this.selectedSector;
      this.selectedSectorData = priceFilter.currentValue;
    }

    if (this.selected50DAvgChg && this.selected50DAvgChg !== 'any') {
      filterObject['50DAvgChg'] = this.selected50DAvgChg;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === '50DAvgChg');
      priceFilter.currentValue = this.selected50DAvgChg;
      this.selected50DAvgChgData = priceFilter.currentValue;
    }

    if (this.selected200DAvgChg && this.selected200DAvgChg !== 'any') {
      filterObject['200DAvgChg'] = this.selected200DAvgChg;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === '200DAvgChg');
      priceFilter.currentValue = this.selected200DAvgChg;
      this.selected200DAvgChgData = priceFilter.currentValue;
    }
    if (this.selected52WHighChg && this.selected52WHighChg !== 'any') {
      filterObject['52WHighChg'] = this.selected52WHighChg;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === '52WHighChg');
      priceFilter.currentValue = this.selected52WHighChg;
      this.selected52WHighChgData = priceFilter.currentValue;
    }
    this.goBack();
  }

  applyFilterList() {
    let filterObject = { page: 1, pageSize: 50 };
    FILTERSETUPDATA.forEach((item) => {
      if (!item.currentValue || item.currentValue === this.selectedPriceData) {
        filterObject['price'] = this.selectedPriceData ? this.selectedPriceData : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedVolumeData) {
        filterObject['volume'] = this.selectedVolumeData ? this.selectedVolumeData : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedWeeklyOptionData) {
        filterObject['isWeeklyStock'] =
          this.selectedWeeklyOptionData === 'yes'
            ? true
            : this.selectedWeeklyOptionData === 'no'
            ? false
            : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedEarningsData) {
        filterObject['earnings'] = this.selectedEarningsData ? this.selectedEarningsData : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedMarketCapData) {
        filterObject['marketCap'] = this.selectedMarketCapData ? this.selectedMarketCapData : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedChangeData) {
        filterObject['change'] = this.selectedChangeData ? this.selectedChangeData : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedCountryData) {
        filterObject['country'] = this.selectedCountryData ? this.selectedCountryData : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedIndustryData) {
        filterObject['industry'] = this.selectedIndustryData ? this.selectedIndustryData : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedSectorData) {
        filterObject['sector'] = this.selectedSectorData ? this.selectedSectorData : null;
      }
      if (!item.currentValue || item.currentValue === this.selected50DAvgChgData) {
        filterObject['50DAvgChg'] = this.selected50DAvgChgData ? this.selected50DAvgChgData : null;
      }
      if (!item.currentValue || item.currentValue === this.selected200DAvgChgData) {
        filterObject['200DAvgChg'] = this.selected200DAvgChgData
          ? this.selected200DAvgChgData
          : null;
      }
      if (!item.currentValue || item.currentValue === this.selected52WHighChgData) {
        filterObject['52WHighChg'] = this.selected52WHighChgData
          ? this.selected52WHighChgData
          : null;
      }
    });

    const filterCount = this.calculateFilterCount();
    this.filterDataObject = { ...this.filterDataObject, ...filterObject };
    this.tradeToolsService
      .setupScannerWeeklyStock(this.filterDataObject)
      .subscribe((result: any) => {
        this.isLoading = true;
        this.menuService.setCloseMenuSubject(this.filterDataObject);
        this.showForm = false;
        this.toastServ.presentToast(result?.message, 'success');
        const { data = [], meta } = result;
        this.page = {
          offset: parseInt(meta.page, 10) - 1,
          size: meta?.perPage,
          totalElements: meta?.total,
          totalPages: meta?.pages,
        };
        this.rows = data;

        this.cancel('false');
        this.applyFilterData();
        this.menuService.triggerChildResetFilter({ ...result, filterCount });
      });
    this.isLoading = false;
  }

  applyFilterData() {
    const newFilters = {
      price: this.selectedPriceData,
      volume: this.selectedVolumeData,
      isWeeklyStock:
        this.selectedWeeklyOptionData != null
          ? this.selectedWeeklyOptionData === 'yes'
            ? true
            : false
          : null,
      earnings: this.selectedEarningsData,
      sector: this.selectedSectorData,
      industry: this.selectedIndustryData,
      marketCap: this.selectedMarketCapData,
      country: this.selectedCountryData,
      change: this.selectedChangeData,
    };
    this.setupScannerService.updateFilterObject(newFilters);
    this.showFilterData = newFilters;
  }

  private calculateFilterCount(): number {
    let count = 0;
    if (this.selectedPrice && this.selectedPrice !== 'any') count++;
    if (this.selectedVolume && this.selectedVolume !== 'any') count++;
    if (this.selectedWeeklyOption && this.selectedWeeklyOption !== 'any') count++;
    if (this.selectedEarnings && this.selectedEarnings !== 'any') count++;
    if (this.selectedMarketCap && this.selectedMarketCap !== 'any') count++;
    if (this.selectedChange && this.selectedChange !== 'any') count++;
    if (this.selectedCountry && this.selectedCountry !== 'any') count++;
    if (this.selectedIndustry && this.selectedIndustry !== 'any') count++;
    if (this.selectedSector && this.selectedSector !== 'any') count++;
    if (this.selected50DAvgChg && this.selected50DAvgChg !== 'any') count++;
    if (this.selected200DAvgChg && this.selected200DAvgChg !== 'any') count++;
    if (this.selected52WHighChg && this.selected52WHighChg !== 'any') count++;
    return count;
  }

  clearSpecificFilter(type) {
    let filterObject = { page: 1, pageSize: 50 };
    if (type == 'price') {
      filterObject['price'] = null;
      this.selectedPrice = null;
      this.selectedPriceData = null;
    }
    if (type == 'volume') {
      filterObject['volume'] = null;
      this.selectedVolume = null;
      this.selectedVolumeData = null;
    }
    if (type == 'isWeeklyStock') {
      filterObject['isWeeklyStock'] = null;
      this.selectedWeeklyOption = null;
      this.selectedWeeklyOptionData = null;
    }
    if (type == 'earnings') {
      filterObject['earnings'] = null;
      this.selectedEarnings = null;
      this.selectedEarningsData = null;
    }
    if (type == 'marketcap') {
      filterObject['marketcap'] = null;
      this.selectedMarketCap = null;
      this.selectedMarketCapData = null;
    }
    if (type == 'changes(%)') {
      filterObject['changes(%)'] = null;
      this.selectedChange = null;
      this.selectedChangeData = null;
    }
    if (type == 'country') {
      filterObject['country'] = null;
      this.selectedCountry = null;
      this.selectedCountryData = null;
    }
    if (type == 'industry') {
      filterObject['industry'] = null;
      this.selectedIndustry = null;
      this.selectedIndustryData = null;
    }
    if (type == 'sector') {
      filterObject['sector'] = null;
      this.selectedSectorData = null;
    }
    if (type == '50DAvgChg') {
      filterObject['50DAvgChg'] = null;
      this.selected50DAvgChgData = null;
    }
    if (type == '200DAvgChg') {
      filterObject['200DAvgChg'] = null;
      this.selected200DAvgChgData = null;
    }
    if (type == '52WHighChg') {
      filterObject['52WHighChg'] = null;
      this.selected52WHighChgData = null;
    }

    /* const filterCount = this.calculateFilterCount();
    filterObject['filterCount'] = filterCount;
    filterObject['page'] = this.page.offset + 1;
    filterObject['pageSize'] = this.page.size;
    this.filterDataObject = { ...this.filterDataObject, ...filterObject };
    this.tradeToolsService
      .setupScannerWeeklyStock(this.filterDataObject)
      .subscribe((result: any) => {
        this.menuService.setCloseMenuSubject(this.filterDataObject);
        this.showForm = false;
        this.toastServ.presentToast(result?.message, 'success');
        const { data = [], meta } = result;
        this.page = {
          offset: parseInt(meta.page, 10) - 1,
          size: meta?.perPage,
          totalElements: meta?.total,
          totalPages: meta?.pages,
        };
        this.rows = data;
        this.menuService.triggerChildResetFilter({ ...result, filterCount });
      }); */
  }
}
export interface SetupScannerRow {
  name: string;
  description: string;
  _id: string;
}
