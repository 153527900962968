import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { InAppNotificationComponent } from 'src/app/components/in-app-notification/in-app-notifications.component';
import { ChannelTypeService } from 'src/app/services/channel-type.service';
import { ChatService } from 'src/app/services/chat.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProductGroupsService } from 'src/app/services/product-groups.service';
import { SettingService } from 'src/app/services/setting.service';
import { StorageService } from 'src/app/services/storage.service';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { environment } from '../../../environments/environment';
import { SupportPage } from '../../scenes/common/support/support.page';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { Capacitor } from '@capacitor/core';
import { TradeToolsService } from 'src/app/services/trade-tools-setting';
import { HubInformationComponent } from '../hub-information/hub-information.component';
import { log } from 'console';
@Component({
  selector: 'app-user-layout',
  templateUrl: './user-layout.component.html',
  styleUrls: ['./user-layout.component.scss'],
})
export class UserLayoutComponent implements OnInit, OnDestroy {
  public appVersion = environment.version;
  public channels: any;
  public userData: any;
  public tools: any;
  public subscriptions = [];
  public channelUpdates = false;
  public storeUrl = 'https://my-store-11498366.creator-spring.com';
  public userId: any = null;
  public routeBaseRole = 'user';
  public isChatActive = false;
  public totalGroups = 0;
  public pages: any = [
    {
      title: 'Dashboard',
      url: `/${this.routeBaseRole}/dashboard`,
      icon: 'grid-outline',
      sortOrder: 1,
      callback: this.generalCallback.bind(this),
    },
    /**
     * @todo
     * 2 Times sidebar displayed.
     */
    // {
    //   title: 'Team Gear',
    //   icon: 'cart-outline',
    //   sortOrder: 6,
    //   callback: this.redirectOnTeamGear.bind(this),
    // },
    // {
    //   title: 'Support',
    //   icon: 'call-outline',
    //   sortOrder: 7,
    //   callback: this.presentSupportPopup.bind(this),
    // },
    /**
     * @todo
     * admin,user as well as staff this not showing modules.
     */

    {
      title: 'Tools',
      icon: 'hammer-outline',
      /*  childrens: [
        {
          title: 'Trade Trackers',
          url: `/${this.routeBaseRole}/tools/trade-trackers/`,
        },
        {
          title: 'Dashboard',
          url: `/${this.routeBaseRole}/tools/dashboard`,
        },
      ], */
      sortOrder: 9,
      callback: this.generalCallback.bind(this),
    },
  ];
  role: string;
  tag: number[];
  constructor(
    public storageServ: StorageService,
    private router: Router,
    private groupServ: ProductGroupsService,
    private chatService: ChatService,
    private platform: Platform,
    private settingServ: SettingService,
    private channelService: ChannelTypeService,
    private tradeToolsService: TradeToolsService,
    private notificationService: NotificationService,
    private modalController: ModalController,
    private broadcastService: BroadcastService,
    private ngxToastr: ToastrService
  ) {
    this.userId = this.storageServ.get('userId');
    this.userData = JSON.parse(this.storageServ.get('userData') || '{}');
    this.tag = this.storageServ.get('tags') ? JSON.parse(this.storageServ.get('tags')) : [];
    this.role = this.storageServ.get('role');
    this.configureOnesignal();
  }

  ngAfterViewInit() {
    if (this.userData && !this.userData?.isWalkThroughCompleted && this.userData?.role === 'user') {
      this.checkForWalkIn();
    }
  }

  async checkForWalkIn() {
    let Props: any = {
      formType: 'hubInformationType',
      userInfo: this.userData,
    };
    const modal = await this.modalController.create({
      component: HubInformationComponent, // Your modal page component
      cssClass: 'modal-buyers-information',
      showBackdrop: true,
      backdropDismiss: false,
      componentProps: {
        ...Props,
      },
    });
    await modal.present();
  }

  configureOnesignal() {
    this.platform
      .ready()
      .then(() => {
        if (Capacitor.getPlatform() !== 'web') {
          this.broadcastService.configureNativePush();
        } else {
          this.broadcastService.configureWebPush();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  ngOnInit() {
    this.clearChannelData();
    this.getProductGroupsForUsers();
    if (this.userId) {
      this.chatService.establishSocketConnection(this.userId);
      this.notificationService.establishSocketConnection(this.userId);
    }

    const channelCountUpdateSubscription = this.chatService
      .onChannelCountUpdate()
      .subscribe((counts: any) => {
        if (counts.length && this.channels) {
          /* tslint:disable */
          for (let i = 0; i < counts.length; i++) {
            const channelCount = counts[i];
            const channelIndex = this.channels.findIndex(
              (c: any) => c._id === channelCount.channelId
            );
            if (channelIndex !== -1) {
              this.channels[channelIndex].count = channelCount.count;
            }
          }

          if (this.channels.some((c) => c.count && c.count > 0) && !this.isChatActive) {
            this.channelUpdates = true;
          } else {
            this.channelUpdates = false;
          }
        }
      });

    // In-app notification listener
    const newNotificationSubscription = this.notificationService
      .onNewInAppNotification()
      .subscribe((notification: any) => {
        if (notification && Capacitor.getPlatform() == 'web') {
          const notificationInstance = new NotificationsComponent(
            this.notificationService,
            this.storageServ,
            this.router,
            null
          );

          const [notificationFormated] = notificationInstance.formatNotifications([notification]);
          if (notificationFormated) {
            this.presentInAppNotification(notificationFormated);
          }
        }
      });

    this.subscriptions.push(channelCountUpdateSubscription, newNotificationSubscription);
  }

  checkTag(sub) {
    return !(
      this.role === 'user' &&
      sub &&
      sub.title &&
      sub.title === 'Money Press Onramp' &&
      this.tag &&
      this.tag.length &&
      this.tag.indexOf(6245) > -1
    );
  }

  presentInAppNotification(notification) {
    let inAppTitle = '';

    if (notification.type === 'ChatComment') {
      inAppTitle = 'Post Comment';
    } else if (notification.type === 'Broadcast') {
      inAppTitle = 'Broadcast';
    } else if (notification.type === 'InsiderChat') {
      inAppTitle = 'Insider Room';
    }

    const toastRef = this.ngxToastr.success('', inAppTitle, {
      toastComponent: InAppNotificationComponent,
      timeOut: 30000,
      extendedTimeOut: 6000,
      enableHtml: true,
      newestOnTop: true,
      progressBar: true,
      progressAnimation: 'increasing',
      closeButton: true,
      easing: 'ease-in',
      easeTime: 300,
      positionClass: 'toast-bottom-left',
      // @ts-ignore
      payload: notification,
    });

    toastRef.onTap.pipe(take(1)).subscribe(() => {
      if (notification) {
        const { url, queryParams } = notification.hrefLink;

        this.notificationService.markAsReadNotification(notification.id);

        this.router.navigate([`${url}`], {
          queryParams,
          queryParamsHandling: 'merge',
        });
      }
    });

    toastRef.onAction.subscribe(() => {
      this.ngxToastr.clear(toastRef.toastId);
    });
  }

  getSortedPages = () => this.pages.sort((a, b) => a.sortOrder - b.sortOrder);

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  getProductGroupsForUsers() {
    this.groupServ.getProductGroupsForUser().subscribe((response: any) => {
      const { data: groups = [] } = response;
      for (let i = 0; i < groups.length; i++) {
        const group = groups[i];
        let products = group?.products || [];
        let childrens = [];

        if (products.length) {
          /**
           * @todo
           * need to check this sortorder is returned from the backend its affecting any other place or not
           */
          products = products.sort((a, b) => a.sortOrder - b.sortOrder);
        }
        for (let j = 0; j < products.length; j++) {
          const product = products[j];
          childrens.push({
            title: product.title,
            url: `/${this.routeBaseRole}/product-groups/${group.slug}/product/${product.slug}`,
            source: product,
          });
        }

        this.pages.push({
          title: group.title,
          slug: group.slug,
          icon: group.icon,
          childrens,
          sortOrder: i + 3,
          source: group,
          callback: this.generalCallback.bind(this),
        });
        this.totalGroups++;
      }

      this.prepareChatRoutes();
    });
  }

  generalCallback() {
    this.storageServ.remove('channelId');
    this.isChatActive = false;
  }

  async prepareChatRoutes() {
    try {
      const [chatSettingPromise, chatChannelsPromise] = await Promise.all([
        this.settingServ.getAllSetting(),
        this.channelService.getAllChannelTypes(),
      ]);

      const getChatChannels = async (parentSlug): Promise<any> => {
        return new Promise((resolve) => {
          chatChannelsPromise.subscribe((channelsResponse: any) => {
            let { channels = [] } = channelsResponse.data;
            this.chatService.pingOnChannelsLoaded();
            this.channels = channels;

            let channelRoutes = channels.map((channel: any) => {
              return {
                title: channel.title,
                url: `/${this.routeBaseRole}/platinum-chat/${parentSlug}/channel/${channel.slug}`,
                source: channel,
              };
            });
            resolve(channelRoutes);
          });
        });
      };

      chatSettingPromise.subscribe(async (chatSettingResponse: any) => {
        if (chatSettingResponse) {
          const {
            setting: [chatSetting = null],
          } = chatSettingResponse.data;
          if (chatSetting) {
            const childrens = await getChatChannels(chatSetting.slug);
            this.pages.push({
              title: chatSetting.title,
              slug: chatSetting.slug,
              icon: chatSetting.icon,
              childrens,
              sortOrder: this.totalGroups + 3,
              source: chatSetting,
              callback: this.chatMenuCallback,
            });
          }

          await this.prepareToolRoutes();

          this.pages = [
            ...this.pages,
            {
              title: 'Team Gear',
              icon: 'cart-outline',
              sortOrder: this.totalGroups + 4,
              callback: this.redirectOnTeamGear.bind(this),
            },
            {
              title: 'Support',
              icon: 'call-outline',
              sortOrder: this.totalGroups + 5,
              callback: this.presentSupportPopup.bind(this),
            },
          ];
        }
      });
    } catch (error) {}
  }

  async prepareToolRoutes() {
    try {
      const [chatSettingPromise, tradeToolPromise] = await Promise.all([
        this.settingServ.getAllSetting(),
        this.tradeToolsService.getAllToolTypes(),
      ]);

      const getTools = async (): Promise<any> => {
        return new Promise((resolve) => {
          tradeToolPromise.subscribe((toolResponse: any) => {
            let { tools = [] } = toolResponse.data;
            // this.chatService.pingOnChannelsLoaded();
            this.tools = tools;

            let toolRoutes = [];

            tools.forEach((tool: any) => {
              if (tool.slug === '9ibJ9zY') {
                toolRoutes.push({
                  title: tool.title,
                  url: `/${this.routeBaseRole}/tools/trade-trackers/${tool.slug}`,
                  source: tool,
                });
              } else if (tool.slug === 'r6VAKez') {
                toolRoutes.push({
                  title: tool.title,
                  url: `/${this.routeBaseRole}/tools/dashboard/${tool.slug}`,
                  source: tool,
                });
              } else if (tool.slug === 'thi2qAn') {
                toolRoutes.push({
                  title: tool.title,
                  url: `/${this.routeBaseRole}/tools/watchList/${tool.slug}`,
                  source: tool,
                });
              } else if (tool.slug === 'BPp8kdj') {
                toolRoutes.push({
                  title: tool.title,
                  url: `/${this.routeBaseRole}/tools/setup-scanner/${tool.slug}`,
                  source: tool,
                });
              }
            });

            resolve(toolRoutes);
          });
        });
      };
      const childrens = await getTools();
      if (childrens && childrens.length) {
        for (let i = 0; i < this.pages.length; i++) {
          let menu = this.pages[i];
          if (menu?.title !== 'Tools') {
            continue;
          }
          this.pages[i].childrens = childrens;
        }
      }

      /*   chatSettingPromise.subscribe(async (toolSettingResponse: any) => {
          if (toolSettingResponse) {
            const {
              setting: [toolSetting = null],
            } = toolSettingResponse.data;
  
            if (toolSetting) {
              const childrens = await getTools(toolSetting.slug);
              this.pages.push({
                title: toolSetting.title,
                slug: toolSetting.slug,
                icon: toolSetting.icon,
                childrens,
                sortOrder: this.totalGroups + 3,
                source: toolSetting,
                callback: this.chatMenuCallback,
              });
            }
  
  
          }
        }); */
    } catch (error) {}
  }

  chatMenuCallback() {
    this.isChatActive = true;
  }

  clearChannelData() {
    this.storageServ.remove('channelId');
  }

  async presentSupportPopup() {
    const modal = await this.modalController.create({
      component: SupportPage,
      cssClass: 'support-modal',
    });
    return await modal.present();
  }

  redirectOnTeamGear() {
    window.open(this.storeUrl, '_blank');
  }
}
