import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Page } from 'src/app/models/page';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { LoaderService } from 'src/app/services/loader.service';
import { SetupScannerService } from 'src/app/services/setup-scanner.service';
import { StorageService } from 'src/app/services/storage.service';
import { ToastService } from 'src/app/services/toast.service';
import { TradeToolsService } from 'src/app/services/trade-tools-setting';
import { CHANGE, COLORSETUPSCANNER, MARKET_CAP, PRICE, VOLUME } from 'src/app/utils/constant';

@Component({
  selector: 'app-setup-scanner',
  templateUrl: './setup-scanner.component.html',
  styleUrls: ['./setup-scanner.component.scss'],
})
export class SetupScannerComponent implements OnInit {
  mode: string;
  lookupListVisibility: any;
  searchResult: any;
  showDatatable: boolean;
  public tag = [];
  public page = new Page();
  public offset: number;
  pageSize = 10;
  currentPage = 1;
  public isToolAccess = false;
  public lockedPageContent: null;
  selectedMarketCap: string;
  selectedPrice: string;
  selectedChange: string;
  selectedVolume: string;
  chunkedData = [];
  MARKET_CAP = MARKET_CAP;
  PRICE = PRICE;
  CHANGE = CHANGE;
  VOLUME = VOLUME;
  COLORSETUPSCANNER = COLORSETUPSCANNER;
  filterDataObject = {};
  setupScannerRow: SetupScannerRow = {
    name: '',
    description: '',
    _id: '',
  };
  staticData = [
    {
      _id: '671909008099039009072932',
      name: 'Weekly Option Stocks',
      description: 'It refer to stocks that have options contracts with weekly expiration dates.',
      image: '/assets/icon/Rectangle 31.png',
    },
    {
      name: 'Surprise Factor',
      image: '/assets/icon/Rectangle 30.png',
    },
    {
      name: 'Crowbar',
      image: '/assets/icon/Rectangle 31 (1).png',
    },
    {
      name: 'Sunrise/Demise',
      image: '/assets/icon/Rectangle 31 (2).png',
    },
    {
      name: 'Stocks on the move',
      image: '/assets/icon/Rectangle 32.png',
    },
    {
      name: 'Ideal setup',
      image: '/assets/icon/Rectangle 32 (1).png',
    },
  ];
  constructor(
    private menuService: commonRightMenuService,
    private tradeToolsService: TradeToolsService,
    private activatedRoute: ActivatedRoute,
    private storageServ: StorageService,
    public loaderService: LoaderService,
    private toastServ: ToastService,
    private router: Router,
    private setupScannerService: SetupScannerService
  ) {
    this.lockedPageContent = null;
    this.role = this.storageServ.get('role');
    this.tag = this.storageServ.get('tags') ? JSON.parse(this.storageServ.get('tags')) : [];
    this.activeToolSlug = this.activatedRoute?.snapshot.params.id;
    if (this.activeToolSlug) {
      this.tradeToolsService
        .getToolDetails(this.activeToolSlug, 'true')
        .subscribe((toolResponse: any) => {
          const { tools: toolDetails = null } = toolResponse.data;
          if (toolDetails) {
            this.storageServ.set('toolId', JSON.stringify(toolDetails));
            this.handletoolInit(toolDetails);
          }
        });
    }
  }
  role: any;
  tool: any;
  row: any;
  public isNoData: Boolean = true;
  [x: string]: any;
  public toolTitle: string;
  public toolIsReadOnlyForUser = false;
  public toolReadOnlyMessage = '';

  selectedLookup: {
    name: string;
    symbol: string;
    exchange: string;
  } = {
    name: '',
    symbol: '',
    exchange: '',
  };

  ngOnInit() {
    this.menuService.getTriggerChildSetupScannerReset().subscribe((data) => {
      this.setupScannerRow.name = data.name ? data.name : this.setupScannerRow.name;
      this.setupScannerRow.description = data.description
        ? data.description
        : this.setupScannerRow.description;
        this.isNoData = false;
    });
    this.menuService.getTriggerSymbolReset().subscribe((data) => {
      this.getSetupScannerData();
    });
    this.getSetupScannerData();
  }

  async createEditSetupScanner(mode) {
    this.mode = mode;
    let setupScannerProps: any = {
      mode,
      componentType: 'SetupScannerAddUpdateComponent',
    };
    const activeToolSlug = this.activeToolSlug;
    setupScannerProps.activeToolSlug = activeToolSlug;
    if (mode == 'edit') {
      setupScannerProps.editData = this.watchListRow;
    }
    this.menuService.openRightMenu(setupScannerProps);
  }
  handletoolInit(tool) {
    this.tool = tool;
    if (this.tool && this.role) {
      const { readonly = false, postAllowedRoles = [], status = null, tags } = this.tool;
      if (status && status === 'locked' && !readonly) {
        const allIdsExist = tags.some((id) => this.tag.includes(Number(id)));
        if (allIdsExist) {
          this.isToolAccess = true;
          this.lockedPageContent = null;
        } else {
          this.loaderService.HideLoader();
          this.toolTitle = tool.title;
          this.lockedPageContent = tool.lockedPageContent;
        }
      } else if (status && status === 'published') {
        this.isToolAccess = true;
        this.lockedPageContent = null;
      } else {
        if (readonly) {
          this.toolIsReadOnlyForUser = !postAllowedRoles.includes(this.userRole);
          if (this.toolIsReadOnlyForUser) {
            this.toolReadOnlyMessage =
              'The channel has been opened with specific levels of access, you are not permitted to post. For more information, please email thecrew@tradersedgenetwork.com.';
          }
        }
      }
    }
  }

  back() {
    window.location.reload();
  }

  getHeaderClassChangePercent({ row }): any {
    return {
      'custom-cell-green': Number(row.regularMarketChangePercent) >= 0,
      'custom-cell-red': Number(row.regularMarketChangePercent) < 0,
    };
  }

  getSetupScannerData() {
    this.setupScannerService.getAllSetupScanner().subscribe((response: any) => {
      if (response && response?.data) {
        this.setupScannerList = response?.data?.setupScanners;
        const setupScannerListLength = this.setupScannerList.length;
        this.setupScannerList = this.setupScannerList.map((scanner: any) => {
          const scannerName = scanner.name.trim().charAt(0).toUpperCase();

          const colorMatch = COLORSETUPSCANNER.find((color) => {
            const colorName = color.name.trim().toUpperCase();
            return colorName === scannerName;
          });

          return {
            ...scanner,
            firstLetter: scannerName ? scannerName : null,
            colorspan: colorMatch ? colorMatch.color : null,
            color: colorMatch ? colorMatch.value : null, // Assign color if found, else null
          };
        });
        if (this.setupScannerList.length === 0) {
          this.isNoData = true;
        } else {
          this.isNoData = false;
        }
        
      }
    });
  }
}
export interface SetupScannerRow {
  name: string;
  description: string;
  _id: string;
}
