import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';
@Component({
  selector: 'app-recents-alerts',
  templateUrl: './recents-alerts.component.html',
  styleUrls: ['./recents-alerts.component.css'],
})
export class RecentsAlertsComponent implements OnInit {
  @Input() alertData: string;
  @Input() activeToolSlug: string;
  recentData: any;
  role: any;
  constructor(
    public modalController: ModalController,
    private router: Router,
    public storageServ: StorageService,
    private toolsApi: ToolServiceService
  ) {
    this.role = this.storageServ.get('role');
  }

  ngOnInit() {
    this.recentData = this.alertData;
  }

  onSymbolWiseView(symbol, segmentValue: string = 'alert', alertType: string) {
    this.toolsApi.setSegmentValue(segmentValue);
    this.toolsApi.setAlertValue(alertType);
    this.router.navigate([
      `/${this.role}/tools/dashboard/${this.activeToolSlug}/stock-info/${symbol}`,
    ]);
    this.close();
  }

  close() {
    this.modalController.dismiss(null);
  }
}
