export const environment = {
  production: true,
  cronSidebarView: true,
  firebase: {
    apiKey: 'AIzaSyDspRlh4Q4qnPduRmNh5rCwm49QtiFcEhg',
    authDomain: 'ten-team-hub.firebaseapp.com',
    projectId: 'ten-team-hub',
    storageBucket: 'ten-team-hub.appspot.com',
    messagingSenderId: '894798371571',
    appId: '1:894798371571:web:a0068c65c04720e7cd3586',
    measurementId: 'G-06G5CFXGBS',
    vapidKey:
      'BDKsouhDTegK43ksf6YyGmjjlgmW9s0K47TbrYUeugde5KPNXtUo09perfya-3qTbDOIhqj8vMipRfxgj8D-_yo',
  },
  firebaseSenderId: '894798371571',
  onesignalAppId: 'c65e5848-e017-4c4f-bca7-a19462e55d14',
  impersonateSecret: '8hqBwkswnWQZ4er40CkDUEA',
  version: '0.0.481',
  infusionsoftClientId: 'd2mJiBIrbmk5je03VYhUJODD9j3sSRYz',
};
export let hostUrl: any = 'https://learning-stage.tradersedgenetwork.com';
export let baseUrl: any = 'https://learning-stage.tradersedgenetwork.com/api';
export let chatUrl: any = 'https://learning-stage.tradersedgenetwork.com';
export let tinyKey: any = 'bz8jat5yx5ibg9hd1slplx7u3xhgwu92ggoto3wwxtsivf0f';
export let backupUrl: any = 'https://learning-stage.tradersedgenetwork.com/8uhYG';
export let uploadsS3BucketBasePath = 'https://hub-uploads-stage.s3.us-east-2.amazonaws.com';
export let mbomBaseUrl = 'test/api/v1';
