import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, PopoverController, ToastController } from '@ionic/angular';
import { StorageService } from '../../../services/storage.service';
import { LoginService } from '../../../services/login.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProfileService } from 'src/app/services/profile.service';
import { NotificationsPopoverPage } from './notifications-popover';
import { ProfileMenuPopoverPage } from './profile-menu-popover';
import { Capacitor } from '@capacitor/core';
import { NotificationsModalPage } from './notification-modal';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
@Component({
  selector: 'app-common',
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.scss'],
})
export class CommonComponent implements OnInit, OnDestroy, AfterViewInit {
  /* tslint:disable */
  image: any;
  selectOptions = {
    interface: 'popover',
  };

  username;
  email: string = localStorage.getItem('email');
  role: string = localStorage.getItem('role');
  impersonatePreviewDismissed: string = localStorage.getItem('impersonal-preview-dismissed');
  impersonateModeEnabled: string = localStorage.getItem('impersonate_mode_enabled');
  notifications: any = [];
  notificationBedgeCount = 0;
  subscriptions = [];
  showNotifications = false;
  alertShow = false;

  constructor(
    private storageserv: StorageService,
    private router: Router,
    private loginserv: LoginService,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    public toastController: ToastController,
    private profileServ: ProfileService,
    private popoverCtrl: PopoverController,
    public modalController: ModalController,
    private menuService: commonRightMenuService
  ) {}

  ngOnInit() {
    this.getUserDetails();

    // establish socket connection
    const userId = this.storageserv.get('userId');
    this.role = this.storageserv.get('role');
    if (userId) {
      this.notificationService.fetchNotifications();
    }

    const notificationSubscription = this.notificationService
      .onNotifications()
      .subscribe((response: any) => {
        if (response) {
          const { notifications = [], unreadCount = 0 } = response;
          this.notifications = this.formatNotifications(notifications);
          this.notificationBedgeCount = unreadCount;
        }
      });

    const profileLoadSubscription = this.profileServ
      .onPingLoadProfile()
      .subscribe((loadProducts: any) => {
        if (loadProducts) {
          this.getUserDetails();
          this.profileServ.tickForLoadProfile(false);
        }
      });

    this.subscriptions.push(notificationSubscription, profileLoadSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  async helpAddUpdate() {
    let helpProps: any = {
      componentType: 'HelpSectionComponent',
    };
    this.menuService.openRightMenu(helpProps);
  }

  formatNotifications(notifications) {
    if (!notifications.length) {
      return [];
    }
    const notificationArray = [];
    const getAuthorName = (author) => {
      return `${author.firstName || ''} ${author.lastName || ''}`;
    };
    const getNotificationHref = (
      loggedInUserRole,
      notificationType,
      groupSlug,
      productSlug,
      categorySlug,
      subCategorySlug = null,
      postSlug,
      commentID,
      depth
    ) => {
      if (notificationType === 'ChatComment') {
        if (loggedInUserRole !== 'user') {
          if (subCategorySlug) {
            return {
              url: `/${loggedInUserRole}/products/comments/${groupSlug}/product/${productSlug}
              /category/${categorySlug}/content/${subCategorySlug}/sub-content/${postSlug}`,
              queryParams: { focus: commentID, depth },
            };
          } else {
            return {
              url: `/${loggedInUserRole}/products/comments/${groupSlug}/product/${productSlug}
              /category/${categorySlug}/content/${postSlug}`,
              queryParams: { focus: commentID, depth },
            };
          }
        } else {
          if (subCategorySlug) {
            return {
              url: `/${loggedInUserRole}/product-groups/${groupSlug}/product/${productSlug}
              /category/${categorySlug}/content/${subCategorySlug}/sub-content/${postSlug}`,
              queryParams: { focus: commentID, depth },
            };
          } else {
            return {
              url: `/${loggedInUserRole}/product-groups/${groupSlug}/product/${productSlug}
              /category/${categorySlug}/content/${postSlug}`,
              queryParams: { focus: commentID, depth },
            };
          }
        }
      } else if (notificationType === 'Broadcast') {
        if (['admin', 'staff'].includes(loggedInUserRole)) {
          if (subCategorySlug) {
            return {
              url: `/${loggedInUserRole}/products/${groupSlug}/product/${productSlug}
              /category/${categorySlug}/content/${subCategorySlug}/sub-content/${postSlug}`,
              queryParams: {},
            };
          } else {
            return {
              url: `/${loggedInUserRole}/products/${groupSlug}/product/${productSlug}
              /category/${categorySlug}/content/${postSlug}`,
              queryParams: {},
            };
          }
        } else if (loggedInUserRole === 'user') {
          if (subCategorySlug) {
            return {
              url: `/${loggedInUserRole}/product-groups/${groupSlug}/product/${productSlug}
              /category/${categorySlug}/content/${subCategorySlug}/sub-content/${postSlug}`,
              queryParams: {},
            };
          } else {
            return {
              url: `/${loggedInUserRole}/product-groups/${groupSlug}/product/${productSlug}
              /category/${categorySlug}/content/${postSlug}`,
              queryParams: {},
            };
          }
        }
      }
    };

    for (let i = 0; i < notifications.length; i++) {
      const notification = notifications[i];
      const loggedInUserId = this.storageserv.get('userId');

      if (!notification.notificationType) {
        continue;
      }

      const {
        comment = null,
        subCategory = null,
        post = null,
        postId,
        createdAt,
        is_read = false,
        thread = null,
        parentThread = null,
        channel = null,
      } = notification;
      let postDetails = null;
      let postProductDetails = null;
      let groupSlug = null;
      let productSlug = null;
      let categorySlug = null;
      let subCategorySlug = null;
      let postSlug = null;
      let commentID = null;
      let depth = 0;

      switch (notification.notificationType) {
        case 'ChatComment':
          if (!notification.comment) {
            continue;
          }
          if (!post && subCategory && subCategory.category && subCategory.posts) {
            const { posts = [], category } = subCategory;
            const subCategoryPost = posts.find((p) => p._id === postId);
            if (!subCategoryPost || !category || !category.product || !category.product.group) {
              continue;
            }

            postDetails = subCategoryPost;
            postProductDetails = category.product;
            groupSlug = category.product.group.slug;
            productSlug = category.product.slug;
            categorySlug = category.slug;
            subCategorySlug = subCategory.slug;
            postSlug = subCategoryPost.slug;
            commentID = comment._id;
            depth = comment.depth;
          } else if (post && !subCategory) {
            const { category } = post;
            if (!category || !category.product || !category.product.group) {
              continue;
            }

            postDetails = post;
            postProductDetails = category.product;
            groupSlug = category.product.group.slug;
            productSlug = category.product.slug;
            categorySlug = category.slug;
            postSlug = post.slug;
            commentID = comment._id;
            depth = comment.depth;
          }

          if (postDetails && postProductDetails) {
            const { authorId: commentAuthor = null } = comment;
            const authorName = getAuthorName(commentAuthor);

            if (commentAuthor.image) {
              commentAuthor.image = commentAuthor.image;
            } else {
              commentAuthor.image = '';
            }

            const { title: postTitle = '' } = postDetails;
            const { title: productTitle = '' } = postProductDetails;
            const notificationURL = getNotificationHref(
              this.role,
              notification.notificationType,
              groupSlug,
              productSlug,
              categorySlug,
              subCategorySlug,
              postSlug,
              commentID,
              depth
            );
            if (
              loggedInUserId &&
              notification.parentComment &&
              notification.parentComment.authorId &&
              notification.parentComment.authorId._id === loggedInUserId
            ) {
              const ntfs = {
                id: notification._id,
                message: `<div class="message"><span>${authorName}</span> has replied to your comment in post 
                <span>${postTitle}</span> in product <span>${productTitle}<span></div>`,
                commentAuthor,
                comment,
                dateTime: createdAt,
                isRead: is_read,
                hrefLink: notificationURL,
                type: notification.notificationType,
              };

              notificationArray.push(ntfs);
            } else {
              const ntfs = {
                id: notification._id,
                message: `<div class="message"><span>${authorName}</span> commented on the post 
                <span>${postTitle}</span> in product <span>${productTitle}<span></div>`,
                commentAuthor,
                comment,
                dateTime: createdAt,
                isRead: is_read,
                hrefLink: notificationURL,
                type: notification.notificationType,
              };

              notificationArray.push(ntfs);
            }
          }
          break;
        case 'Broadcast':
          if (!post && subCategory && subCategory.category && subCategory.posts) {
            const { posts = [], category } = subCategory;
            const subCategoryPost = posts.find((p) => p._id === postId);
            if (!subCategoryPost || !category || !category.product || !category.product.group) {
              continue;
            }

            postDetails = subCategoryPost;
            postProductDetails = category.product;
            groupSlug = category.product.group.slug;
            productSlug = category.product.slug;
            categorySlug = category.slug;
            subCategorySlug = subCategory.slug;
            postSlug = subCategoryPost.slug;
          } else if (post && !subCategory) {
            const { category } = post;
            if (!category || !category.product || !category.product.group) {
              continue;
            }

            postDetails = post;
            postProductDetails = category.product;
            groupSlug = category.product.group.slug;
            productSlug = category.product.slug;
            categorySlug = category.slug;
            postSlug = post.slug;
          }

          if (postDetails && postProductDetails) {
            const { title: postTitle = '', body: postBody = '' } = postDetails;
            const notificationURL = getNotificationHref(
              this.role,
              notification.notificationType,
              groupSlug,
              productSlug,
              categorySlug,
              subCategorySlug,
              postSlug,
              commentID,
              depth
            );
            const ntfs = {
              id: notification._id,
              subject: postTitle,
              message: postBody,
              dateTime: createdAt,
              isRead: is_read,
              hrefLink: notificationURL,
              type: notification.notificationType,
            };

            notificationArray.push(ntfs);
          }
          break;
        case 'InsiderChat':
          if (!thread || !channel) {
            continue;
          }

          const { authorId: threadAuthor = null } = thread;
          const authorName = getAuthorName(threadAuthor);
          const channelSettingSlug = channel?.settingId ? channel?.settingId?.slug : 'chat';
          const notificationURL = {
            url: `/${this.role}/platinum-chat/${channelSettingSlug}/channel/${channel.slug}`,
            queryParams: { focus: thread._id },
          };

          if (!threadAuthor.image) {
            threadAuthor.image = '';
          }

          if (
            loggedInUserId &&
            parentThread &&
            parentThread.authorId &&
            parentThread.authorId._id === loggedInUserId
          ) {
            const ntfs = {
              id: notification._id,
              message: `<div class="message"><span>${authorName}</span> has replied to your message in
                    <span>${channel.title}</span> insider room.</div>`,
              threadAuthor,
              thread,
              dateTime: createdAt,
              isRead: is_read,
              hrefLink: notificationURL,
              type: notification.notificationType,
            };

            notificationArray.push(ntfs);
          } else {
            const ntfs = {
              id: notification._id,
              message: `<div class="message"><span>${authorName}</span> posted a new message in 
                    <span>${channel.title}</span> insider room.</div>`,
              threadAuthor,
              thread,
              dateTime: createdAt,
              isRead: is_read,
              hrefLink: notificationURL,
              type: notification.notificationType,
            };

            notificationArray.push(ntfs);
          }
        default:
          break;
      }
    }
    return notificationArray;
  }

  notificationRedirect({ url, queryParams }, notificationId) {
    this.notificationService.markAsReadNotification(notificationId);
    this.router.navigate([`${url}`], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  navigateUrl(url) {
    this.router.navigate([`${this.role}${url}`]);
  }

  viewAllNotifications() {
    this.router.navigate([`/${this.role}/notifications`]);
  }

  markAllRead() {
    this.notificationService.markAllReadNotifications();
  }

  async openNotification(ev: any) {
    if (Capacitor.getPlatform() == 'web') {
      const popover = await this.popoverCtrl.create({
        component: NotificationsPopoverPage,
        cssClass: 'header-notification-popover',
        event: ev,
        translucent: false,
        showBackdrop: false,
        mode: 'ios',
        componentProps: {
          notifications: this.notifications,
          notificationBedgeCount: this.notificationBedgeCount,
          triggerMarkAllRead: this.markAllRead.bind(this),
          triggerNotificationRedirect: this.notificationRedirect.bind(this),
          triggerViewAllNotifications: this.viewAllNotifications.bind(this),
        },
      });
      await popover.present();
    } else {
      const modal = await this.modalController.create({
        component: NotificationsModalPage,
        cssClass: 'header-notification-modal',
        componentProps: {
          notifications: this.notifications,
          notificationBedgeCount: this.notificationBedgeCount,
          triggerMarkAllRead: this.markAllRead.bind(this),
          triggerNotificationRedirect: this.notificationRedirect.bind(this),
          triggerViewAllNotifications: this.viewAllNotifications.bind(this),
        },
      });

      return await modal.present();
    }
  }

  async openProfileMenu(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: ProfileMenuPopoverPage,
      cssClass: 'header-profilemenu-popover',
      event: ev,
      translucent: false,
      showBackdrop: false,
      mode: 'ios',
      componentProps: {
        username: this.username,
        role: this.role,
        impersonateModeEnabled: this.impersonateModeEnabled,
        triggerGoToSettings: this.goToSettings.bind(this),
        triggerImpersonateModeSignin: this.impersonateModeSignin.bind(this),
        triggerNavigateUrl: this.navigateUrl.bind(this),
        triggerLogout: this.logout.bind(this),
        triggerLogoutImporsonateMode: this.logoutImporsonateMode.bind(this),
      },
    });
    await popover.present();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.alertShow = true;
      this.cdr.detectChanges();
    }, 1000);
  }

  onDismissPreview() {
    localStorage.setItem('impersonal-preview-dismissed', 'true');
    this.impersonatePreviewDismissed = 'true';
    this.logoutImporsonateMode();
  }

  async impersonateModeSignin() {
    const query = {
      impersonate: true,
      token: localStorage.getItem('token'),
      email: this.email,
      base_user_email: this.email,
      base_user_role: this.role,
      impersonateRole: 'user',
      setSession: true,
    };
    await this.loginserv.logout(true);
    this.router.navigate(['/login'], {
      queryParams: {
        ...query,
      },
    });
  }

  getUserDetails() {
    const user: any = JSON.parse(this.storageserv.get('userData') || '{}');
    if (user && Object.keys(user).length) {
      this.username = (user.firstName || '') + ' ' + (user.lastName || '');
      this.email = user.email || '';
      this.image = user.image || '';
      this.role = user.role || 'user';
    }
  }

  logout() {
    this.loginserv.logout();
  }

  async logoutImporsonateMode() {
    const query = {
      impersonate: true,
      token: localStorage.getItem('baseusertoken'),
      email: localStorage.getItem('base_user_email'),
      impersonateRole: localStorage.getItem('base_user_role'),
      setSession: false,
    };

    await this.loginserv.logout(true);
    this.router.navigate(['/login'], {
      queryParams: {
        ...query,
      },
    });
  }

  goToSettings() {
    const role = this.storageserv.get('role') || 'user';
    this.router.navigate([`/${role}/settings`]);
  }
}
