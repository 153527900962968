<div class="drawer-header">
  <div class="header-container">
    <div class="page-header">
      <div class="page-heading-text">Trade History - AFRM </div>
      <ion-buttons slot="end" >
        <ion-button (click)="close()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
  </div>
</div>
<ng-container>
  <div class="trade-history-wrap">
    <div class="trade-history-block active">
      <div class="current-view" *ngIf="!isActiveCurrentView">Currently Viewing</div>
      <div class="trader-data">
        <span>Date Opened :</span>
        <span class="value">12 Sep 24</span>
      </div>
      <div class="trader-data">
        <span>Trade Duration :</span>
        <span class="value">40 Days</span>
      </div>
      <div class="trader-data">
        <span>POS. P/L :</span>
        <span class="value red-text">-$62.00</span>
      </div>
      <div class="trader-data">
        <span>Notes :</span>
        <span class="value"> <ion-icon slot="start" name="document-text-outline"></ion-icon> 1 Note</span>
      </div>
      <div class="trader-data">
        <span>Status :</span>
        <ion-button class="open-btn">Open</ion-button>
      </div>

    </div>
    <div class="trade-history-block">
      <div class="current-view" *ngIf="isActiveCurrentView">Currently Viewing</div>
      <div class="trader-data">
        <span>Date Opened :</span>
        <span class="value">12 Sep 24</span>
      </div>
      <div class="trader-data">
        <span>Trade Duration :</span>
        <span class="value">40 Days</span>
      </div>
      <div class="trader-data">
        <span>POS. P/L :</span>
        <span class="value green-text">$62.00</span>
      </div>
      <div class="trader-data">
        <span>Notes :</span>
        <span class="value"> <ion-icon slot="start" name="document-text-outline"></ion-icon> 1 Note</span>
      </div>
      <div class="trader-data">
        <span>Status :</span>
        <ion-button class="close-btn">Closed</ion-button>
      </div>
    </div>
  </div>
</ng-container>