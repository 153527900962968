import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { chatUrl, baseUrl } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private socket;
  public notifications = new BehaviorSubject(null);
  public inAppNotification = new BehaviorSubject(null);

  constructor(private http: HttpClient, private storageService: StorageService) {}

  // socket listeners
  establishSocketConnection = (userId) => {
    this.socket = io(`${chatUrl}/notification`, {
      path: '/socket.io',
      forceNew: true,
      query: {
        user: userId,
      },
      transports: ['websocket'],
    });

    // Connection events
    this.socket.on('connect', () => {
      const engine = this.socket.io.engine;

      console.log(`notification socket connected...`);

      engine.once('upgrade', () =>
        console.log('notification transport upgraded=>', engine.transport.name)
      );
      engine.on('packetCreate', ({ type }) => console.log('notification packet sent =>', type));
      engine.on('packet', ({ type }) => {
        console.log('notification packet received =>', type);
      });

      engine.on('error', (error) => {
        console.log('notification engine error..', error);
      });

      engine.on('close', (reason) => {
        console.log('notification: socket underlying connection is closed =>', reason);
      });
    });

    this.socket.on('connect_error', (err) => {
      console.log(`notification: socket connection error => ${err}`);
    });

    this.socket.on('error', (error) => {
      console.log(`notification: socket error...`, error);
    });

    this.socket.on('disconnect', () => {
      console.log(`notification: socket disconnected...`);
    });

    // Notification Events
    this.socket.on('notifications', (notifications) => {
      this.notifications.next(notifications);
    });

    this.socket.on('new-notification-created', (notification) => {
      this.inAppNotification.next(notification);
    });

    return Promise.resolve();
  };

  public fetchNotifications = () => {
    if (this.socket) {
      this.socket.emit('fetch_notifications');
    }
  };

  public markAsReadNotification = (notificationId) => {
    if (this.socket) {
      this.socket.emit('mark_as_read_notification', notificationId);
    }
  };

  public markAllReadNotifications = () => {
    if (this.socket) {
      this.socket.emit('mark_all_read_notifications');
    }
  };

  public clearNotification = (notificationId) => {
    if (this.socket) {
      this.socket.emit('clear_notification', notificationId);
    }
  };

  public clearAllNotifications = () => {
    if (this.socket) {
      this.socket.emit('clear_all_notification');
    }
  };

  // on events
  public onNotifications(): Observable<any> {
    return this.notifications.asObservable();
  }

  public onNewInAppNotification(): Observable<any> {
    return this.inAppNotification.asObservable();
  }

  // api methods
  public fetchAllNotifications(page, pageSize) {
    const qp = new URLSearchParams();
    if (page) {
      qp.set('page', page);
    }
    if (pageSize) {
      qp.set('pageSize', pageSize);
    }
    return this.http.get(`${baseUrl}/notifications/listing?${qp.toString()}`, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.storageService.get('token')}`),
    });
  }
}
