<ion-content class="ion-padding app-ticker-details" appScrollbar>
  <ol class="cd-breadcrumb custom-separator custom-icons">
    <li><a routerLink="/{{ role }}/dashboard">Tools</a></li>
    <li>
      <a routerLink="/{{ role }}/tools/trade-trackers/{{ toolSlug }}">Trade Trackers</a>
    </li>
    <li><a (click)="back()">Money Press Method</a></li>
    <li class="current" *ngIf="stockDetailRow && stockDetailRow?.tickerName">
      {{ stockDetailRow?.tickerName | titlecase }}
    </li>
  </ol>

  <div class="row-page-heading">
    <div class="row">
      <span class="back-trade" (click)="back()"> < Back To Money Press Trade Tracker</span>
    </div>
    <div class="page-heading">
      <div class="actions">
        <ion-buttons>
          <ion-button
            class="border-style"
            (click)="openRightMenuNotes()"
            color="primary"
            fill="solid"
          >
            <ion-icon slot="start" name="document-text-outline"></ion-icon>
            <span>{{ stockDetailRow?.notesCount }} Notes</span>
          </ion-button>

          <div class="more-btn">
            <ion-button class="more-btn" (click)="toggleDropdown($event, row)">
              <img src="../../../../../../assets/icon/more.png" />
            </ion-button>
            <div class="dropdown-menu dropdown-content" *ngIf="isDropdownOpen">
              <svg
                class="svg-bg"
                width="174"
                height="80"
                viewBox="0 0 174 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask id="path-1-inside-1_4123_4235" fill="white">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M154.295 9L147.5 0L140.705 9H4C1.79086 9 0 10.7909 0 13V76C0 78.2091 1.79086 80 4 80H170C172.209 80 174 78.2091 174 76V13C174 10.7909 172.209 9 170 9H154.295Z"
                  />
                </mask>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M154.295 9L147.5 0L140.705 9H4C1.79086 9 0 10.7909 0 13V76C0 78.2091 1.79086 80 4 80H170C172.209 80 174 78.2091 174 76V13C174 10.7909 172.209 9 170 9H154.295Z"
                  fill="white"
                />
                <path
                  d="M147.5 0L148.298 -0.602549L147.5 -1.65962L146.702 -0.602549L147.5 0ZM154.295 9L153.497 9.60255L153.797 10H154.295V9ZM140.705 9V10H141.203L141.503 9.60255L140.705 9ZM146.702 0.602549L153.497 9.60255L155.093 8.39745L148.298 -0.602549L146.702 0.602549ZM141.503 9.60255L148.298 0.602549L146.702 -0.602549L139.907 8.39745L141.503 9.60255ZM4 10H140.705V8H4V10ZM1 13C1 11.3431 2.34315 10 4 10V8C1.23858 8 -1 10.2386 -1 13H1ZM1 76V13H-1V76H1ZM4 79C2.34315 79 1 77.6569 1 76H-1C-1 78.7614 1.23857 81 4 81V79ZM170 79H4V81H170V79ZM173 76C173 77.6569 171.657 79 170 79V81C172.761 81 175 78.7614 175 76H173ZM173 13V76H175V13H173ZM170 10C171.657 10 173 11.3431 173 13H175C175 10.2386 172.761 8 170 8V10ZM154.295 10H170V8H154.295V10Z"
                  fill="#B3B3B3"
                  mask="url(#path-1-inside-1_4123_4235)"
                />
              </svg>

              <div class="arrow"></div>
              <ion-item
                id=""
                (click)="showTradeDelete()"
                lines="none"
                class="first ion-no-padding width-css dropdown-item"
                >Close Trade</ion-item
              >
              <ion-item
                id=""
                (click)="openRightMenuTraders()"
                lines="none"
                class="second ion-no-padding width-css dropdown-item"
                >Trade History</ion-item
              >
            </div>
          </div>

          <!-- <ion-button class="border-style" (click)="openRightMenu()" color="primary" fill="solid"> -->
          <!-- <ion-icon slot="start" name="notifications-outline"></ion-icon> -->
          <!-- <span>{{ stockDetailRow?.alertsCount }} Alerts</span> -->
          <!-- </ion-button> -->
        </ion-buttons>
      </div>
    </div>
  </div>
  <ion-row>
    <ion-col size-xs="12" size-sm="12" size-md="12" size-xl="8" size-lg="8">
      <div class="share-details">
        <ion-card class="ion-no-margin ion-margin-bottom">
          <ion-card-content>
            <div class="share">
              <div class="share-name">
                <div class="title">
                  <ion-card-title *ngIf="stockDetailRow && stockDetailRow.tickerName"
                    >{{ stockDetailRow?.symbol }} -
                  </ion-card-title>
                  <h1 style="color: #4f4f4f; font-weight: 700; margin-bottom: 0">
                    {{ stockDetailRow?.tickerName }}
                  </h1>
                </div>
                <div class="action" *ngIf="stockDetailRow?.stockUrl">
                  <span class="logo"
                    ><img [src]="stockDetailRow?.stockUrl" alt="" srcset=""
                  /></span>
                </div>
                <div class="action" *ngIf="iframeSrc && !stockDetailRow?.stockUrl">
                  <span class="logo log-dynamic"><img [src]="iframeSrc" alt="" srcset="" /></span>
                </div>
              </div>
              <div
                class="ticker-description-container ion-flex-container ion-align-items-left ion-flex-wrap"
              >
                <div
                  class="desc-item ion-flex-container ion-align-items-left ion-flex-wrap ion-flex-column"
                >
                  <span>INDUSTRY:</span>
                  <span>{{ stockDetailRow?.industry }}</span>
                </div>
                <span class="desc-divider"></span>
                <div
                  class="desc-item ion-flex-container ion-align-items-left ion-flex-wrap ion-flex-column"
                >
                  <span>SECTOR:</span>
                  <span>{{ stockDetailRow?.sector }}</span>
                </div>
                <span class="desc-divider"></span>
                <div
                  class="desc-item ion-flex-container ion-align-items-left ion-flex-wrap ion-flex-column"
                >
                  <span>CURRENT PRICE:</span>
                  <div
                    *ngIf="stockDetailRow && stockDetailRow.stockPrice"
                    class="ion-flex-container ion-align-items-left ion-flex-gap-default"
                  >
                    <span>{{ stockDetailRow?.stockPrice | currency }}</span>
                  </div>
                </div>
                <span class="desc-divider"></span>
                <!-- <div -->
                <!-- class="desc-item ion-flex-container ion-align-items-left ion-flex-wrap ion-flex-column" -->
                <!-- > -->
                <!-- <span>EARNING DATE:</span> -->
                <!-- <span>{{ tradeEarningsDate }}</span> -->
                <!-- </div> -->
                <div
                  class="desc-item ion-flex-container ion-align-items-left ion-flex-wrap ion-flex-column"
                >
                  <span>TODAY'S CHANGE:</span>
                  <div class="ion-flex-container ion-align-items-left ion-flex-gap-default">
                    <ng-container *ngIf="stockDetailRow?.regularMarketChange > 0">
                      <ion-icon name="arrow-up" color="primary" style="font-size: 20px"></ion-icon>
                    </ng-container>
                    <ng-container *ngIf="stockDetailRow?.regularMarketChange < 0">
                      <ion-icon name="arrow-down" color="danger" style="font-size: 20px"></ion-icon>
                    </ng-container>
                    <span [ngClass]="getHeaderClassTodayChange({ stockDetailRow: stockDetailRow })"
                      >{{ stockDetailRow?.regularMarketChange | currency }}({{
                        stockDetailRow?.marketChangePercentage
                      }}%)</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </ion-card-content>
        </ion-card>
        <div class="profit-n-loss">
          <div class="profit">
            <ion-card class="ion-no-margin">
              <ion-card-content class="ion-no-padding">
                <ion-grid class="ion-no-padding">
                  <ion-row>
                    <ion-col size-xs="12" size-sm="12" size-md="12" size-xl="6" size-lg="6"
                      ><span class="today-pnl-title">Earnings Date:</span></ion-col
                    >
                    <ion-col
                      [ngClass]="getClassForEarningDays({ stockDetailRow: stockDetailRow })"
                      size-xs="12"
                      size-sm="12"
                      size-md="12"
                      size-xl="6"
                      size-lg="6"
                    >
                      <span
                        class="earning-date-value"
                        *ngIf="stockDetailRow?.earningStartDate != stockDetailRow?.earningEndDate"
                      >
                        ~ {{ stockDetailRow?.earningStartDate }}
                        <div
                          class="tooltip"
                          *ngIf="stockDetailRow?.earningStartDate != stockDetailRow?.earningEndDate"
                        >
                          <ion-icon name="alert-circle"></ion-icon>
                          <span class="tooltiptext"
                            >The earnings date is currently an estimate. As the date gets closer the
                            actual earnings date will be confirmed.</span
                          >
                        </div>
                      </span>

                      <span
                        class="earning-date-value"
                        *ngIf="stockDetailRow?.earningStartDate == stockDetailRow?.earningEndDate"
                        ><ion-icon name="checkmark-circle"></ion-icon
                        >{{ stockDetailRow?.earningStartDate }}</span
                      ></ion-col
                    >
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </div>
          <div class="loss">
            <ion-card class="ion-no-margin">
              <ion-card-content class="ion-no-padding">
                <ion-grid class="ion-no-padding">
                  <ion-row>
                    <ion-col size-xs="12" size-sm="12" size-md="12" size-xl="6" size-lg="6"
                      ><span class="today-pnl-title">Position P/L</span></ion-col
                    >
                    <ion-col
                      [ngClass]="
                        getHeaderClassPositionPnL({ row: { totalProfitLoss: calculatePnL() } })
                      "
                      size-xs="12"
                      size-sm="12"
                      size-md="12"
                      size-xl="6"
                      size-lg="6"
                    >
                      <span class="earning-date-value" *ngIf="isIntervalLoader"
                        ><ion-spinner name="dots"></ion-spinner
                      ></span>
                      <span *ngIf="!isIntervalLoader" class="today-pnl-value">
                        {{ calculatePnL() | currency }}</span
                      >
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </div>
        </div>
      </div>
    </ion-col>
    <ion-col class="left-col" size-xs="12" size-sm="12" size-md="12" size-xl="4" size-lg="4">
      <div class="account-details ion-no-padding">
        <ion-card class="ion-no-margin" style="height: 100%">
          <ion-card-content class="ion-no-padding">
            <ion-list lines="full" [inset]="true" class="list-ticker-portfolio-details">
              <ion-item>
                <div class="detail-container">
                  <div class="details">
                    <div class="label-icon">
                      <span>ACCOUNT SIZE:</span>
                      <div class="icon-div" (click)="accountSizeRedirect()">
                        <ion-icon slot="icon-only" name="create-outline"></ion-icon>
                      </div>
                    </div>
                    <span class="account-value">{{ row?.accountSize | currency }}</span>
                  </div>
                </div>
              </ion-item>
              <ion-item>
                <div class="detail-container">
                  <div class="details">
                    <span>MARGIN:</span>
                    <span>{{ row?.margin | currency }}</span>
                  </div>
                </div>
              </ion-item>
              <ion-item>
                <div class="detail-container">
                  <div class="details">
                    <span>PORTFOLIO AT RISK:</span>
                    <span>{{ row?.portpholioRisk?.toFixed(2) }}%</span>
                  </div>
                </div>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-col>
  </ion-row>

  <div class="page-heading">
    <h2>Weekly Sold Put</h2>
    <div class="actions">
      <ion-buttons>
        <div class="action">
          <div class="edit">
            <ion-button (click)="positionAddUpdate('weekly', 'add')" color="primary">
              <ion-icon name="add-circle-outline"></ion-icon>
              ADD WEEKLY LEG
            </ion-button>
          </div>
        </div>
      </ion-buttons>
    </div>
  </div>
  <ion-card class="ion-no-margin card-container">
    <ion-card-content class="ion-no-padding">
      <ngx-datatable
        class="material body-cell-padding-custom body-cell-stacked ticker-details-table"
        [rows]="displayedRowsWeekly"
        [columnMode]="'force'"
        [rowHeight]="'auto'"
        [headerHeight]="50"
        [footerHeight]="50"
        [scrollbarH]="true"
      >
        <ngx-datatable-column [width]="104" [sortable]="false">
          <ng-template ngx-datatable-header-template> DATE SOLD </ng-template>
          <ng-template let-row="row" let-dateSold="row.dateSold" ngx-datatable-cell-template>
            {{ dateSold | date : 'MM/dd/yyyy' : 'UTC' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="104" [sortable]="false">
          <ng-template ngx-datatable-header-template>EXPIRATION DATE</ng-template>
          <ng-template
            let-row="row"
            let-expirationPeriod="row.expirationPeriod"
            ngx-datatable-cell-template
          >
            {{ expirationPeriod | date : 'MM/dd/yyyy' : 'UTC' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="90" [sortable]="false">
          <ng-template ngx-datatable-header-template> STRIKE SOLD </ng-template>
          <ng-template let-row="row" let-strikeSold="row.strikeSold" ngx-datatable-cell-template>
            {{ strikeSold | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="108" [sortable]="false">
          <ng-template ngx-datatable-header-template> UNDERLYING PRICE </ng-template>
          <ng-template
            let-row="row"
            let-underlyingStock="row.underlyingStock"
            ngx-datatable-cell-template
          >
            {{ underlyingStock | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="105" [sortable]="false">
          <ng-template ngx-datatable-header-template> #OF CONTRACTS </ng-template>
          <ng-template
            let-row="row"
            let-numberOfContracts="row.numberOfContracts"
            ngx-datatable-cell-template
          >
            {{ numberOfContracts }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="90" [sortable]="false">
          <ng-template ngx-datatable-header-template>CREDIT</ng-template>
          <ng-template let-row="row" let-premiumSold="row.premiumSold" ngx-datatable-cell-template>
            {{ premiumSold | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="90" [sortable]="false">
          <ng-template ngx-datatable-header-template> EXIT PREMIUM </ng-template>
          <ng-template let-row="row" let-exitPremium="row.exitPremium" ngx-datatable-cell-template>
            <span
              *ngIf="!isIntervalLoader || row.positionStatus === 'closed'"
              [style.color]="getColorBasedOnStatus(row.positionStatus)"
            >
              {{ exitPremium | currency }}</span
            >
            <span *ngIf="checkForLoader(row) && isIntervalLoader">
              <ion-spinner name="dots"></ion-spinner>
            </span>
          </ng-template>
        </ngx-datatable-column>
        <!-- <ngx-datatable-column [sortable]="false"> -->
        <!-- <ng-template ngx-datatable-header-template> PREMIUM EARNED </ng-template> -->
        <!-- <ng-template -->
        <!-- let-row="row" -->
        <!-- let-premiumEarned="row.premiumEarned" -->
        <!-- ngx-datatable-cell-template -->
        <!-- > -->
        <!-- <p>{{ premiumEarned }}</p> -->
        <!-- </ng-template> -->
        <!-- </ngx-datatable-column> -->
        <ngx-datatable-column [width]="100" [sortable]="false">
          <ng-template ngx-datatable-header-template> PROFIT/ LOSS </ng-template>
          <ng-template
            let-row="row"
            let-tradeProfitLoss="row.tradeProfitLoss"
            ngx-datatable-cell-template
          >
            <p>{{ tradeProfitLoss | currency }}</p>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="120" prop="cumulative">
          <ng-template ngx-datatable-header-template> CUMULATIVE </ng-template>
          <ng-template
            let-row="row"
            let-cumulativeProfitLoss="row.cumulativeProfitLoss"
            ngx-datatable-cell-template
          >
            <p>{{ cumulativeProfitLoss > 0 ? '+' : '' }}{{ cumulativeProfitLoss | currency }}</p>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="65"
          [cellClass]="getHeaderClassProfitLoss"
          prop="cumulative"
          [sortable]="false"
        >
          <ng-template ngx-datatable-header-template> % </ng-template>
          <ng-template
            let-row="row"
            let-profitLossPercentage="row.profitLossPercentage"
            ngx-datatable-cell-template
          >
            {{ profitLossPercentage }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="110" [sortable]="true">
          <ng-template ngx-datatable-header-template> STATUS </ng-template>
          <ng-template
            let-row="row"
            let-positionStatus="row.positionStatus"
            let-isTotalCumulativeProfitPrice="row.isTotalCumulativeProfitPrice"
            ngx-datatable-cell-template
          >
            <ion-chip
              *ngIf="!isTotalCumulativeProfitPrice"
              [outline]="true"
              [color]="positionStatus == 'closed' ? 'danger' : 'primary'"
            >
              <span
                class="status-dot"
                [ngClass]="{
                  'dot-position-close': positionStatus == 'closed',
                  'dot-position-open': positionStatus == 'open'
                }"
              ></span>
              <ion-text>
                {{ positionStatus | uppercase }}
              </ion-text>
            </ion-chip>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="80" class="custom-button" [sortable]="false">
          <ng-template ngx-datatable-header-template> ACTION </ng-template>
          <ng-template
            let-row="row"
            let-isTotalCumulativeProfitPrice="row.isTotalCumulativeProfitPrice"
            ngx-datatable-cell-template
          >
            <ion-buttons
              *ngIf="!isTotalCumulativeProfitPrice"
              slot="primary"
              class="ion-justify-content-end custom-button ion-buttons-custom"
            >
              <ion-button
                class="p-0 edit-icon"
                color="medium"
                fill="clear"
                (click)="
                  $event.preventDefault();
                  $event.stopPropagation();
                  positionAddUpdate('weekly', 'edit', row)
                "
              >
                <ion-icon slot="icon-only" name="create-outline"></ion-icon>
              </ion-button>
              <ion-button
                class="p-0 delete-icon"
                color="medium"
                fill="clear"
                (click)="
                  $event.preventDefault();
                  $event.stopPropagation();
                  openConfirmationDialog(row, 'weekly')
                "
              >
                <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer></ngx-datatable-footer>
      </ngx-datatable>
      <div class="show-more-button" *ngIf="weeklyPutPosition.length > 4">
        <button class="show-button" ion-button (click)="toggleWeeklyShowMore()">
          {{
            showAllWeeklyRows
              ? 'SHOW LESS'
              : 'SHOW&nbsp;&nbsp;' + (weeklyPutPosition.length - 4) + '&nbsp;&nbsp;MORE'
          }}
        </button>
      </div>
    </ion-card-content>
  </ion-card>
  <div class="page-heading ion-padding-top">
    <h2>Long Put Protection</h2>
    <div class="actions">
      <ion-buttons>
        <div class="action">
          <div class="edit">
            <ion-button (click)="positionAddUpdate('long', 'add')" color="primary">
              <ion-icon name="add-circle-outline"></ion-icon>
              ADD PROTECTIVE LEG
            </ion-button>
          </div>
        </div>
      </ion-buttons>
    </div>
  </div>
  <ion-card class="ion-no-margin card-container">
    <ion-card-content class="ion-no-padding">
      <ngx-datatable
        class="material body-cell-padding-custom body-cell-stacked ticker-details-table"
        [rows]="displayedRowsLong"
        [columnMode]="'force'"
        [rowHeight]="'auto'"
        [headerHeight]="50"
        [footerHeight]="50"
        [scrollbarH]="true"
      >
        <ngx-datatable-column [width]="104" [sortable]="false">
          <ng-template ngx-datatable-header-template>DATE PURCHASED</ng-template>
          <ng-template
            let-row="row"
            let-datePurchased="row.datePurchased"
            ngx-datatable-cell-template
          >
            {{ datePurchased | date : 'MM/dd/yyyy' : 'UTC' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="104" [sortable]="false">
          <ng-template ngx-datatable-header-template>EXPIRATION DATE</ng-template>
          <ng-template
            let-row="row"
            let-expirationPeriod="row.expirationPeriod"
            ngx-datatable-cell-template
          >
            {{ expirationPeriod | date : 'MM/dd/yyyy' : 'UTC' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="90" [sortable]="false">
          <ng-template ngx-datatable-header-template>STRIKE BOUGHT</ng-template>
          <ng-template
            let-row="row"
            let-strikeBought="row.strikeBought"
            ngx-datatable-cell-template
          >
            {{ strikeBought | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="108" [sortable]="false">
          <ng-template ngx-datatable-header-template>UNDERLYING PRICE</ng-template>
          <ng-template
            let-row="row"
            let-underlyingStock="row.underlyingStock"
            ngx-datatable-cell-template
          >
            {{ underlyingStock | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="105" [sortable]="false">
          <ng-template ngx-datatable-header-template>#OF CONTRACTS</ng-template>
          <ng-template
            let-row="row"
            let-numberOfContracts="row.numberOfContracts"
            ngx-datatable-cell-template
          >
            {{ numberOfContracts }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="90" [sortable]="false">
          <ng-template ngx-datatable-header-template>PREMIUM PAID</ng-template>
          <ng-template let-row="row" let-premiumPaid="row.premiumPaid" ngx-datatable-cell-template>
            {{ premiumPaid | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="90" [sortable]="false">
          <ng-template ngx-datatable-header-template>EXIT PREMIUM</ng-template>
          <ng-template let-row="row" let-exitPremium="row.exitPremium" ngx-datatable-cell-template>
            <span
              *ngIf="!isIntervalLoader || row.positionStatus === 'closed'"
              [style.color]="getColorBasedOnStatus(row.positionStatus)"
            >
              {{ exitPremium | currency }}</span
            >
            <span *ngIf="checkForLoader(row) && isIntervalLoader">
              <ion-spinner name="dots"></ion-spinner>
            </span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="100" [sortable]="false">
          <ng-template ngx-datatable-header-template>PROFIT/LOSS</ng-template>
          <ng-template
            let-row="row"
            let-tradeProfitLoss="row.tradeProfitLoss"
            ngx-datatable-cell-template
          >
            {{ tradeProfitLoss | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="120" prop="cumulative">
          <ng-template ngx-datatable-header-template> CUMULATIVE </ng-template>
          <ng-template
            let-row="row"
            let-cumulativeProfitLoss="row.cumulativeProfitLoss"
            ngx-datatable-cell-template
          >
            {{ cumulativeProfitLoss > 0 ? '+' : '' }}{{ cumulativeProfitLoss | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [cellClass]="getHeaderClassProfitLoss" [width]="65" prop="cumulative">
          <ng-template ngx-datatable-header-template> % </ng-template>
          <ng-template
            let-row="row"
            let-profitLossPercentage="row.profitLossPercentage"
            ngx-datatable-cell-template
          >
            {{ profitLossPercentage }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="110" [sortable]="true">
          <ng-template ngx-datatable-header-template> STATUS </ng-template>
          <ng-template
            let-row="row"
            let-positionStatus="row.positionStatus"
            let-isTotalCumulativeProfitPrice="row.isTotalCumulativeProfitPrice"
            ngx-datatable-cell-template
          >
            <ion-chip
              *ngIf="!isTotalCumulativeProfitPrice"
              [outline]="true"
              [color]="positionStatus == 'closed' ? 'danger' : 'primary'"
            >
              <span
                class="status-dot"
                [ngClass]="{
                  'dot-position-close': positionStatus == 'closed',
                  'dot-position-open': positionStatus == 'open'
                }"
              ></span>
              <ion-text>
                {{ positionStatus | uppercase }}
              </ion-text>
            </ion-chip>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="80" class="custom-button" [sortable]="false">
          <ng-template ngx-datatable-header-template> ACTION </ng-template>
          <ng-template
            let-row="row"
            let-isTotalCumulativeProfitPrice="row.isTotalCumulativeProfitPrice"
            ngx-datatable-cell-template
          >
            <ion-buttons
              *ngIf="!isTotalCumulativeProfitPrice"
              slot="primary"
              class="ion-buttons-custom"
            >
              <ion-button
                class="p-0 edit-icon"
                color="medium"
                fill="clear"
                (click)="
                  $event.preventDefault();
                  $event.stopPropagation();
                  positionAddUpdate('long', 'edit', row)
                "
              >
                <ion-icon slot="icon-only" name="create-outline"></ion-icon>
              </ion-button>
              <ion-button
                class="p-0 delete-icon"
                color="medium"
                fill="clear"
                (click)="
                  $event.preventDefault();
                  $event.stopPropagation();
                  openConfirmationDialog(row, 'long')
                "
              >
                <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer></ngx-datatable-footer>
      </ngx-datatable>
      <div class="show-more-button" *ngIf="longPutPosition.length > 4">
        <button class="show-button" ion-button (click)="toggleLongShowMore()">
          {{
            showAllLongRows
              ? 'SHOW LESS'
              : 'SHOW&nbsp;&nbsp;' + (longPutPosition.length - 4) + '&nbsp;&nbsp;MORE'
          }}
        </button>
      </div>
    </ion-card-content>
  </ion-card>
</ion-content>
