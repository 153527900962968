import { Component, Input, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-trade-history',
  templateUrl: './trade-history.component.html',
  styleUrls: ['./trade-history.component.scss']
})
export class TradeHistoryComponent implements OnInit {
  @Input() tradeHistoryData: any;
  isActiveCurrentView: Boolean = false;
  constructor(public menuController: MenuController  ) { }

  ngOnInit() {
  }
  close() {
    this.menuController.close();
  }
}
